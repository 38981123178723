import { useTranslation } from "react-i18next";

interface NoDataTextProps {
  text?: string;
}

export default function NoDataText(props: NoDataTextProps) {
  const { text } = props;
  const { t } = useTranslation();

  return (
    <div className=" py-4 text-center text-white">
      <b>{text || t('components.noMoreDataAvailable')}</b>
    </div>
  );
}
