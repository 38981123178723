/* eslint-disable react-hooks/exhaustive-deps */
import useContract from "@/store/useContract";
import useWallet from "@/store/useWallet";
import { formatEther } from "ethers";
import { useCallback, useEffect, useState } from "react";
export interface ChainReward {
  smallLinkReward: string; // 小区业绩
  bestLinkReward: string; // 大区业绩
}

export interface LinkUserList {
  address: string;
  amount: string;
  poolBalance: string;
}

export default function useMyLinkGetInfo() {
  // 获取合约
  const {
    contract: { FFR },
  } = useContract();

  const { wallet } = useWallet();

  /**大链小链业绩 */
  const [chainReward, setChainReward] = useState<ChainReward>({
    bestLinkReward: "0",
    smallLinkReward: "0",
  });
  /**总业绩 */
  const [totalReward, setTotalReward] = useState<string>("0");
  /**总业绩和大小链业绩loading */
  const [chainRewardLoading, setChainRewardLoading] = useState(false);

  /**新增业绩 */
  const [newReward, setNewReward] = useState<string>("0");
  const [newRewardLoading, setNewRewardLoading] = useState(false);

  /**直推总数 */
  const [totalAddress, setTotalAddress] = useState<string>("0");
  const [totalAddressLoading, setTotalAddressLoading] = useState(false);

  /**有效直推 */
  const [validAddress, setValidAddress] = useState<string>("0");
  const [validAddressLoading, setValidAddressLoading] = useState(false);

  /**条数ID */
  const [linkUsersPage, setLinkUsersPage] = useState("0");
  const [linkUserList, setLinkUserList] = useState<LinkUserList[]>([]);
  const [linkUserListLoading, setLinkUserListLoading] = useState(false);

  /**
   * @description: 获取用户小链业绩 大链业绩 抓取器
   * @param {string} address
   * @return {*}
   */
  const getLinkPerformanceFetcher = async (address: string) => {
    if (!FFR) return;

    try {
      setChainRewardLoading(true);

      const res = await FFR.getLinkPerformance(address);

      // 总业绩
      const totalLinkReward = formatEther(
        res._smallLinkReward + res._bestLinkReward
      );
      setTotalReward(totalLinkReward);

      // 大链业绩
      const bestLinkReward = formatEther(res._bestLinkReward);
      // 小链业绩
      const smallLinkReward = formatEther(res._smallLinkReward);

      setChainReward({
        bestLinkReward,
        smallLinkReward,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setChainRewardLoading(false);
    }
  };

  /**
   * @description: 获取某天某个用户的直推业绩，同样该值会根据提取情况和质押情况来减少和增加。
   * @param {string} address
   * @return {*}
   */
  const dayUserLinkStakeFetcher = async (address: string) => {
    if (!FFR) return;

    try {
      setNewRewardLoading(true);

      const day = await FFR.getCurDay();

      const res = await FFR.dayUserTeamStake(day, address);
      const newLinkReward = formatEther(res);

      setNewReward(newLinkReward);
    } catch (error) {
      console.error(error);
    } finally {
      setNewRewardLoading(false);
    }
  };

  /**
   * @description: 获取用户直推人数，注册就算直推。
   * @param {string} address
   * @return {*}
   */
  const getDirectNodeNumFetcher = useCallback(
    async (address: string) => {
      if (!FFR) return;

      try {
        setTotalAddressLoading(true);
        const res = await FFR.getDirectNodeNum(address);

        setTotalAddress(res.toString());

        return res.toString();
      } catch (error) {
        console.error(error);
      } finally {
        setTotalAddressLoading(false);
      }
    },
    [FFR]
  );

  /**
   * @description: 获取用户实际有效直推节点数
   * @param {string} address
   * @return {*}
   */
  const getRealValidNodeNumFetcher = useCallback(
    async (address: string) => {
      if (!FFR) return;

      try {
        setValidAddressLoading(true);
        const res = await FFR.getRealValidNodeNum(address);

        setValidAddress(res.toString());
      } catch (error) {
        console.error(error);
      } finally {
        setValidAddressLoading(false);
      }
    },
    [FFR]
  );

  /**
   * @description: 分页获取用户直推用户地址，以及他们的质押金额，当前算力矿池余额。
   * @param {string} address 用户地址
   * @param {number | string} start 开始ID
   * @return {*}
   */
  const getDirLinkUsersFetcher = async (address: string, start: string) => {
    if (!FFR) return;

    let limit = 0;
    if (parseInt(totalAddress) - parseInt(start) >= 10) {
      limit = 10;
    } else if (parseInt(totalAddress) - parseInt(start) <= 0) {
      return;
    } else {
      limit = parseInt(totalAddress) % 10;
    }

    try {
      setLinkUserListLoading(true);
      const list = await FFR.getDirLinkUsers(address, start, limit);

      const newList: LinkUserList[] = [];
      for (let i = 0; i < list.userL0Addr.length; i++) {
        const address = list.userL0Addr[i] as string;
        const amount = formatEther(list.amount[i]);
        const poolBalance = formatEther(list.poolBalance[i]);

        newList.push({
          address,
          amount,
          poolBalance,
        });
      }

      if (start === "0") {
        setLinkUserList(newList);
      } else {
        console.log(start);
        console.log(linkUserList);
        console.log(newList);
        console.log([...linkUserList, ...newList]);
        setLinkUserList([...linkUserList, ...newList]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLinkUserListLoading(false);
    }
  };

  useEffect(() => {
    getLinkPerformanceFetcher(wallet.address);
    dayUserLinkStakeFetcher(wallet.address);
    getDirectNodeNumFetcher(wallet.address);
    getRealValidNodeNumFetcher(wallet.address);
  }, [wallet]);

  useEffect(() => {
    getDirLinkUsersFetcher(wallet.address, linkUsersPage);
  }, [totalAddress, linkUsersPage]);

  return {
    chainReward,
    totalReward,
    chainRewardLoading,
    newReward,
    newRewardLoading,
    totalAddress,
    totalAddressLoading,
    validAddress,
    validAddressLoading,
    linkUserList,
    linkUserListLoading,
    setLinkUsersPage,
    getLinkPerformanceFetcher,
    dayUserLinkStakeFetcher,
    getDirectNodeNumFetcher,
    getRealValidNodeNumFetcher,
  };
}
