import { App } from 'antd';
import useGetGwei from '../web3Hooks/useGetChainInfo';
type NotificationType = 'success' | 'info' | 'warning' | 'error';
// 通知提醒框
function useNotification() {
    const { notification } = App.useApp();
    const { browser } = useGetGwei()
    const openNotification = (type: NotificationType, config: { message: string, description?: string }, hash?: string) => {
        notification[type]({
            ...config,
            onClick: () => {
                hash && window.open(`${browser}tx/${hash}`)
            }
        });
    };
    const showNotification = (type: NotificationType, config: { message: string, description?: string }, hash?: string) => {
        return openNotification(type, config, hash);
    };

    return { showNotification };
}

export default useNotification;
