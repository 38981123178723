import left from "@/image/left.png";
import UsersIcon from "@/image/users.png";
import CopyIcon from "@/image/copy.png";
import TotalPerformanceIcon from "@/image/total-performance.png";
import NewPerformanceIcon from "@/image/new-performance.png";
import BestLinkRewardIcon from "@/image/bestLink-reward.png";
import SmallLinkRewardIcon from "@/image/smallLink-reward.png";
import TotalAddressIcon from "@/image/total-address.png";
import ValidAddressIcon from "@/image/valid-address.png";
import { LoadingOutlined } from "@ant-design/icons";
import Info from "./components/Info";
import Tables from "@/components/Tables";
import { cheengeNumber, mobileHidden } from "@/utils";
import useHandleCopyClick from "@/hooks/useHandleCopyClick";
import useMyLinkGetInfo from "@/web3Hooks/useMyLinkGetInfo";
import useGetUserInfo from "@/web3Hooks/useGetUserInfo";
import { ethereumAddressImage } from "@/utils";
import { useNavigate } from "react-router-dom";
import { truncateDecimals } from "@/utils";
import { useTranslation } from "react-i18next";

export default function MyLink() {
  const { t } = useTranslation()
  // 复制
  const { handleCopyClick } = useHandleCopyClick();

  const { userInfo, userInfoLod } = useGetUserInfo();

  const {
    newReward,
    newRewardLoading,
    chainReward,
    totalReward,
    chainRewardLoading,
    totalAddress,
    totalAddressLoading,
    validAddress,
    validAddressLoading,
    linkUserList,
    linkUserListLoading,
    setLinkUsersPage,
  } = useMyLinkGetInfo();

  const navigate = useNavigate();

  const loadMore = () => {
    setLinkUsersPage(linkUserList.length.toString());
  };

  const columns = [
    {
      title: t('myLink.address'),
      dataIndex: "address",
      render: (text: string) => {
        return (
          <div className="flex items-center justify-center">
            <img
              className=" w-3.5 h-3.5"
              src={ethereumAddressImage(text)}
              alt=""
            />
            <span className=" px-1">{mobileHidden(text, 3, 4, 3)}</span>
            <img
              className=" w-3.5 h-3.5"
              src={CopyIcon}
              alt=""
              onClick={() => handleCopyClick(text)}
            />
          </div>
        );
      },
    },
    {
      title: t('myLink.currentPledgeAmount'),
      dataIndex: "amount",
      render: (amount: string) => {
        return <span>{cheengeNumber(amount, 4, '...')}</span>;
      },
    },
    {
      title: t('myLink.currentComputingPowerMiningPool'),
      dataIndex: "poolBalance",
      render: (poolBalance: string) => {
        return <span>{truncateDecimals(poolBalance, 1)}</span>;
      },
    },
  ];

  return (
    <div className="w-full md:max-w-300 md:mx-auto text-white">
      <div className="w-full h-16 flex items-center pl-4.5 bg-green233 md:hidden">
        <div className="flex items-center cursor-pointer" onClick={() => navigate(-1)}>
          <img className=" w-4 h-4" src={left} alt="" />
          <span className=" ml-2 text-[14px] font-medium">
            {t('myLink.myLinks')}
          </span>
        </div>
      </div>

      <div className="w-full  p-2.5 ">
        <div className="w-full bg-green20 rounded-lg py-6 px-3.5">
          {/* 链接人数 */}
          <div className="flex items-center">
            <img className=" w-3.5 h-3.5" src={UsersIcon} alt="" />
            <span className=" ml-2.5  text-[14px] font-medium">
              <span className=" text-grayA8">{t('myLink.numberOfLinks')}:</span>{" "}
              {userInfoLod ? <LoadingOutlined /> : userInfo.teamNum.toString()}
            </span>
          </div>
          {/* 总量 */}
          <div className="w-full bg-green16  rounded-md mt-4 px-4.5 py-4">
            <div className="w-full   flex justify-between">
              {/* 总总量 */}
              <Info
                title={t('myLink.totalAmountOfPledge')}
                icon={TotalPerformanceIcon}
                value={totalReward}
                loading={chainRewardLoading}
                symbol="FIBO"
              />
              {/* 新增总量 */}
              <Info
                className2=" justify-end"
                title={t('myLink.todayNewTotal')}
                icon={NewPerformanceIcon}
                value={newReward}
                loading={newRewardLoading}
                symbol="FIBO"
              />
            </div>
            {/* 分割线 */}
            <div className="w-full h-[1px] bg-green233 my-4"></div>
            <div className="w-full flex justify-between">
              {/* 大链总量 */}
              <Info
                title={t('myLink.largeChainTotal')}
                icon={BestLinkRewardIcon}
                value={chainReward.bestLinkReward}
                loading={chainRewardLoading}
                symbol="FIBO"
              />
              {/* 小链总量 */}
              <Info
                className1=" flex justify-end"
                className2=" justify-end"
                title={t('myLink.totalSmallChain')}
                icon={SmallLinkRewardIcon}
                loading={chainRewardLoading}
                value={chainReward.smallLinkReward}
                symbol="FIBO"
              />
            </div>
          </div>

          {/* 我的地址 */}
          <div className="w-full mt-4.5 ">
            <h2 className=" text-[14px] font-medium text-grayA8">{t('myLink.myAddress')}</h2>
            <div className="w-full bg-green16    rounded-md px-4.5 py-4 mt-4 flex items-center justify-between">
              {/* 地址数 */}
              <Info
                className2=" justify-center"
                title={t('myLink.myAddressNumber')}
                icon={TotalAddressIcon}
                value={totalAddress}
                loading={totalAddressLoading}
              />
              {/* 有效地址数 */}
              <Info
                className2=" justify-center"
                title={t('myLink.effectiveAddressNumber')}
                icon={ValidAddressIcon}
                value={validAddress}
                loading={validAddressLoading}
              />
            </div>
          </div>

          <div className="w-full mt-4.5">
            <Tables
              columns={columns}
              dataSource={linkUserList}
              loadMore={loadMore}
              loading={linkUserListLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
