import { formatNumber } from "@/utils";
import useFlowDeposit from "@/web3Hooks/useFlowDeposit";
import useMainNetworkCoin from "@/web3Hooks/useMainNetworkCoin";
import { LoadingOutlined } from "@ant-design/icons";
import { Button, Form, InputNumber } from "antd"
import { formatUnits, parseUnits } from "ethers";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import useNotFirstRender from "@/hooks/useNotFirstRender";
import blanceImg from '@/image/blanceImg.png'
const hashrateData = [
    {
        m: '',
        y: ''
    },
    {
        m: '0.8T',
        y: '9.6T'
    }, {
        m: '1.0T',
        y: '12T'
    }, {
        m: '1.2T',
        y: '14.4T'
    }, {
        m: '1.8T',
        y: '21.6T'
    }, {
        m: '2.0T',
        y: '24T'
    }
]
const FlowHashrate = () => {
    const { t } = useTranslation()
    const [form] = Form.useForm();
    // 获取主网币余额
    const { balanceLod, balance, getMainNetworkCoin } = useMainNetworkCoin()
    // 算力显示
    const [hashrate, setHashrate] = useState(hashrateData[0])
    // 质押函数
    const { flowDepositLod, flowDeposit } = useFlowDeposit(() => {
        getMainNetworkCoin()
        form.resetFields();
    })
    // Form校验成功
    const onFinish = (values: any) => {
        flowDeposit(parseUnits(values.pledgeNum + ''))
    };
    // 算力
    const hashrateFn = (value: number) => {
        (value < 100 || value > 30000) && setHashrate(hashrateData[0])
        value >= 100 && value < 1000 && setHashrate(hashrateData[1])
        value >= 1000 && value < 5000 && setHashrate(hashrateData[2])
        value >= 5000 && value < 10000 && setHashrate(hashrateData[3])
        value >= 10000 && value < 20100 && setHashrate(hashrateData[4])
        value >= 20100 && value <= 30000 && setHashrate(hashrateData[5])

    }
    // TODO 上线前打开校验
    // 大于等于100小于等于3万且是100的倍数
    const mathematicalCheck = () => ({
        validator(_: any, value: number) {
            hashrateFn(value)
            if (!value) {
                return Promise.resolve();
            } else if (value < 100 || value > 30000) {
                return Promise.reject(<div className=" pt-2.5 font-normal text-xs">{t('flow.fromText')}</div>);
            } else if (value % 100 !== 0) {
                return Promise.reject(<div className=" pt-2.5 font-normal text-xs">{t('flow.fromText1')}</div>);
            } else if (balance < parseUnits(value + '')) {
                return Promise.reject(<div className=" pt-2.5 font-normal text-xs">{t('flow.fromText2')}</div>);
            } else {
                return Promise.resolve();
            }
        },
    })
    useNotFirstRender(() => {
        form.validateFields()
    }, t)
    return (
        <>
            <div className=" box-border px-3.5 py-9 mt-2.25 mx-4  bg-green27  rounded-lg md:w-125 md:shadow-md md:mx-auto md:mt-6 md:px-8 md:pt-14 md:pb-6 ">
                <Form
                    form={form}
                    className="w-full "
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={() => { }}
                    autoComplete="off"
                >
                    <Form.Item
                        className=" mb-8"
                        required
                        name="pledgeNum"
                        rules={[
                            {
                                required: true,
                                message: <div className=" pt-2.5 font-normal text-xs">{t('flow.fromText3')}</div>,
                            },
                            mathematicalCheck
                        ]}
                    >
                        <div>
                            <div className=" flex text-sm justify-between text-center text-white mb-3.75">
                                <div className="w-[36%]">{t('flow.stakingAmount')}</div>
                                <div className=" w-[28%]">{t('flow.flowComputingPowerMonth')}</div>
                                <div className="w-[28%]">{t('flow.peakComputingPowerYear')}</div>
                            </div>
                            <div className=" flex justify-between text-center ">
                                <InputNumber className="w-[36%]  "
                                    controls={false} placeholder={t('flow.startWith100')} />
                                <div className="w-[28%] h-12 bg-green0B  rounded flex items-center justify-center text-green5B">{hashrate.m}</div>
                                <div className="w-[28%] h-12 bg-green0B rounded flex items-center justify-center text-green5B">{hashrate.y}</div>
                            </div>
                        </div>

                    </Form.Item>
                    <Button className=" mt-1" loading={flowDepositLod} block htmlType="submit">
                        {t('flow.confirm')}
                    </Button>
                    <div className=" hidden  font-medium text-sm  items-center justify-end text-greenC2 mt-6 md:flex">
                        <img className="h-6 mr-1.5" src={blanceImg} alt="" /> {t('flow.balance')}：{balanceLod ? <LoadingOutlined /> : formatNumber(formatUnits(balance), 4)}
                    </div>
                </Form>

            </div>
            <div className=" mx-4 font-medium text-sm flex items-center justify-end text-greenC2 mt-3 md:hidden">
                <img className="h-6 mr-1.5" src={blanceImg} alt="" /> {t('flow.balance')}：{balanceLod ? <LoadingOutlined /> : formatNumber(formatUnits(balance), 4)}
            </div>
        </>
    )
}

export default FlowHashrate