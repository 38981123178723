import { UserInfoType } from "@/interface";
import useContract from "@/store/useContract"
import useWallet from "@/store/useWallet"
import { useCallback, useEffect, useState } from "react"

// 获取用户信息
const useGetUserInfo = () => {
    // 获取地址
    const { wallet: { address } } = useWallet()
    // 获取合约
    const { contract: { FFR } } = useContract()
    // 用户信息
    const [userInfo, setUserInfo] = useState<UserInfoType>({
        referrer: '',         // 推荐人地址
        start: BigInt(0),            // 注册时间
        curLevel: BigInt(0),         // 用户当前等级
        bestLevel: BigInt(0),        // 用户曾经最高的等级
        totalStake: BigInt(0),       // 总合约算力质押量
        totalStakeUsed: BigInt(0),   // 总质押量的消耗，包括提取利润或本金时的消耗
        totalReward: BigInt(0),      // 总合约算力收益
        teamNum: BigInt(0),          // 20代内的团队人数
        poolBalance: BigInt(0),      // 用户的总算力矿池余额
        bestValidNodeNum: BigInt(0), // 该用户最高有效直推人数（最多显示9）
        totalLinkReward: BigInt(0),  // 总链接算力收益
        bestLink: '',         // 该用户的大链地址
    })
    // loading
    const [userInfoLod, setLoading] = useState(false)
    const getUserInfo = useCallback(async () => {
        try {
            setLoading(true)
            if (FFR) {
                const info = await FFR.userInfo(address)
                setUserInfo(info)
            }
        } catch (e) {
            console.log('useGetUserInfo', e);
        }
        setLoading(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [FFR, address])
    useEffect(() => {
        getUserInfo()
    }, [getUserInfo])
    return { userInfo, userInfoLod, getUserInfo }
}

export default useGetUserInfo