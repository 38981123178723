import { Tabs, TabsProps, Tooltip } from "antd"
import FlowHashrate from "./components/flowHashrate";
import History from "./components/history";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import titleImg from '@/image/titleImg.png'
const FlowHash = () => {
  const { t } = useTranslation()
  const [keys, setKeys] = useState('1')
  const items: TabsProps['items'] = [
    {
      key: '1',
      label:
        <div className=" flex items-center">
          {t('flow.flowHashrate')}
          <Tooltip color="#FFFFFF" placement="rightTop" title={<div className="w-32 text-black text-xs">{t('flow.theRevenueCan')}</div>} >
            <img src={titleImg} className=" w-5 h-5" alt="" /> </Tooltip>
        </div >

    },
    {
      key: '2',
      label: t('flow.myBill'),
    }
  ];
  return (
    <>
      <Tabs centered className="box-border pl-5.25 bg-transparent" defaultActiveKey={keys} items={items} onChange={(key: string) => { setKeys(key) }} />
      {keys === '1' && <FlowHashrate />}
      {keys === '2' && <History />}
    </>
  )
}

export default FlowHash