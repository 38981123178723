import useAddressConvert from "@/hooks/useAddressConvert";
import useHandleCopyClick from "@/hooks/useHandleCopyClick";
import { formatTimeToStr, mobileHidden, cheengeNumber } from "@/utils";
import { Tabs, TabsProps, Tooltip } from "antd";
import { useState } from "react";
import CopyGary from "@/image/copy-gary.png";
import useGetLinkProfitsByLimit from "@/web3Hooks/useGetLinkProfitsByLimit";
import useGetUpgradeProfitsByLimit from "@/web3Hooks/useGetUpgradeProfitsByLimit";
import { LoadingOutlined } from "@ant-design/icons";
import Empty from "@/components/Empty";
import { ProfitsByLimitType, UpgradeProfitsType } from "@/interface";
import { formatUnits } from "ethers";
import NoDataText from "@/components/NoDataText";
import { useTranslation } from "react-i18next";
const LinkHash = () => {
  const { t } = useTranslation()
  const [keys, setKeys] = useState("1");
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: t('hashrateRecord.linkComputingPower'),
    },
    {
      key: "2",
      label: t('hashrateRecord.upgradeComputingPower'),
    },
  ];
  return (
    <>
      <Tabs
        className="  px-7"
        centered
        defaultActiveKey="1"
        onChange={(key: string) => {
          setKeys(key);
        }}
        items={items}
      />
      <div className="overflow-y-auto h-mainHs rounded-lg py-3.5 px-3.5 bg-green27 box-border mx-2.5 mt-2.5 md:max-w-300 md:mx-auto">
        {keys === '1' && <LinkProfits keys={keys} />}
        {keys === '2' && <UpgradeProfits keys={keys} />}
      </div>
    </>
  );
};
// 链接算力
const LinkProfits = (props: { keys: string }) => {
  const { keys } = props;
  const { t } = useTranslation()
  // 获取链接算力
  const {
    dataLinkProfits,
    pagesLinkProfits,
    linkProfitsLod,
  } = useGetLinkProfitsByLimit();
  return (
    <>
      {dataLinkProfits.map((item, index) => (
        <Item data={item} key={index} keys={keys} />
      ))}
      <div className=" flex justify-center pt-2">
        {linkProfitsLod && <div className=" text-white mt-3">{t('loading')} <LoadingOutlined /></div>}
        {!dataLinkProfits.length &&
          !linkProfitsLod &&
          !Number(pagesLinkProfits) && <Empty />}
        {!Number(pagesLinkProfits) && !!dataLinkProfits.length && (
          <NoDataText />
        )}
      </div>
    </>
  );
};
// 升级算力
const UpgradeProfits = (props: { keys: string }) => {
  const { keys } = props;
  const { t } = useTranslation()
  // 获取升级算力
  const {
    dataUpgradeProfits,
    pagesUpgradeProfits,
    linkUpgradeProfitsLod,
  } = useGetUpgradeProfitsByLimit();
  return (
    <>
      {dataUpgradeProfits.map((item, index) => (
        <Item data={item} key={index} keys={keys} />
      ))}

      <div className=" flex justify-center pt-2">
        {linkUpgradeProfitsLod && <div className=" text-white mt-3">{t('loading')} <LoadingOutlined /></div>}
        {!dataUpgradeProfits.length &&
          !linkUpgradeProfitsLod &&
          !Number(pagesUpgradeProfits) && <Empty />}
        {!Number(pagesUpgradeProfits) && !!dataUpgradeProfits.length && (
          <NoDataText />
        )}
      </div>
    </>
  );
};
const Item = (props: {
  keys: string;
  data: ProfitsByLimitType | UpgradeProfitsType;
}) => {
  const { t } = useTranslation()
  const { keys, data } = props;
  // 复制
  const { handleCopyClick } = useHandleCopyClick();
  // 地址转换
  const { addressConvert } = useAddressConvert();
  return (
    <div className="flex items-center justify-between py-2.5 px-3.5 rounded bg-green1A mt-2 first:mt-0">
      <div>
        <p className=" text-[13px] mb-2.5 text-white">
          {keys === "1" ? t('hashrateRecord.linkComputingPower') : t('hashrateRecord.upgradeComputingPower')}
        </p>
        <p className=" text-[12px] text-textColor-gray">
          {formatTimeToStr(Number(formatUnits(data.time, 0)) * 1000)}
        </p>
      </div>
      <div className=" text-right ">
        <Tooltip className=" cursor-pointer" title={formatUnits(data.amount)}>
          <p className=" text-[14px] text-white mb-2.5">
            +{cheengeNumber(formatUnits(data.amount), 4, "...")}
          </p>
        </Tooltip>
        {keys === "1" && (
          <p className=" flex items-center text-[12px] text-textColor-gray">
            {mobileHidden(
              addressConvert((data as ProfitsByLimitType)?.provider)
            )}
            <img
              className=" w-3.5 h-3.5 ml-1 cursor-pointer"
              onClick={() => {
                handleCopyClick(
                  addressConvert((data as ProfitsByLimitType)?.provider)
                );
              }}
              src={CopyGary}
              alt=""
            />
          </p>
        )}
      </div>
    </div>
  );
};

export default LinkHash;
