import { Interface, InterfaceAbi } from "ethers";
const FFRAbi = require("./josn/FFR.json");
const FlowDepositAbi = require("./josn/FlowDeposit.json");
const HistoricalOrdersAbi = require("./josn/HistoricalOrders.json");
type objKeyObjectType = {
  [key: string]: { address: string; abi: Interface | InterfaceAbi };
};
let FTContact: objKeyObjectType = {
  FFR: { abi: FFRAbi, address: "" },
  FlowDeposit: { abi: FlowDepositAbi, address: "" },
  HistoricalOrders: { abi: HistoricalOrdersAbi, address: "" },
};
console.log("环境", process.env.REACT_APP_ENV);
if (process.env.REACT_APP_ENV === "development") {
  //开发环境
  FTContact.FFR.address = "0xA4D773327143f2Ac6C29a67Ead036F7384807948";
  FTContact.FlowDeposit.address = "0xD6Aae5E5E28536B5b952873727cab970429FdEd0";
  FTContact.HistoricalOrders.address =
    "0x18a1e0fb986A053579b54D902Bf6a373276d01eD";
} else if (process.env.REACT_APP_ENV === "test") {
  // 测试环境
  FTContact.FFR.address = "0xA4D773327143f2Ac6C29a67Ead036F7384807948";
  FTContact.FlowDeposit.address = "0xD6Aae5E5E28536B5b952873727cab970429FdEd0";
  FTContact.HistoricalOrders.address =
    "0x18a1e0fb986A053579b54D902Bf6a373276d01eD";
} else {
  //生产环境
  FTContact.FFR.address = "0xA4D773327143f2Ac6C29a67Ead036F7384807948";
  FTContact.FlowDeposit.address = "0xD6Aae5E5E28536B5b952873727cab970429FdEd0";
  FTContact.HistoricalOrders.address =
    "0x18a1e0fb986A053579b54D902Bf6a373276d01eD";
}
export default FTContact;
