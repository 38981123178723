import useContract from "@/store/useContract"
import useWallet from "@/store/useWallet"
import { useCallback, useState } from "react"

const useUnextractedEarnings = () => {
    const [unextractedEarnings, setUnextractedEarningsBalance] = useState<bigint>(BigInt(0))
    // loading
    const [unextractedEarningsBalanceLod, setLoading] = useState(false)
    // 获取合约
    const { contract: { FlowDeposit } } = useContract()
    // 地址
    const { wallet: { address } } = useWallet()
    // 获取为提取收益
    const getUnextractedEarnings = useCallback(async (id: bigint) => {
        if (FlowDeposit) {
            setLoading(true)
            try {
                // 获取余额方法
                const res = await FlowDeposit?.pending(address, id)
                setUnextractedEarningsBalance(res)
            } catch (e) {
                console.log('useUnextractedEarnings', e);
                setUnextractedEarningsBalance(BigInt(0))
            }
            setLoading(false)
        }
    }, [FlowDeposit, address])
    return { unextractedEarnings, unextractedEarningsBalanceLod, getUnextractedEarnings }
}

export default useUnextractedEarnings