import { Suspense } from "react";
import { RouterGuard } from "./RouteGuard";
import Layout from "@/Layout";
import FlowHash from "@/pages/flowHash";
import My from "@/pages/my";
import Invitation from "@/pages/invitation";
import LinkHash from "@/pages/linkHash";
import ContractHash from "@/pages/contractHash";
import MyLink from "@/pages/myLink";
import Home from "@/pages/home";
import PageNoBg from "@/pages/pageNoBg";
import PageBg from "@/pages/pageBg";
const routeConfig = [
  {
    path: "/",
    element: <Layout />,
    auth: true,
    children: [
      {
        path: "/",
        element: <Home />,   // tab 首页
        auth: true,
      },
      {
        path: "/flowHash",
        element: <FlowHash />, // tab 流动算力
        auth: true,
      },
      {
        path: "/my",
        element: <My />, // tab 我的
        auth: true,
      },
      {
        path: "/linkHash",
        element: <LinkHash />, // tab 链接算力
        auth: true,
      },
      {
        path: "/contractHash",
        element: <ContractHash />, // tab 合约算力
        auth: true,
      },
      {
        path: "/invitation",
        element: <Invitation />, // 单页面 邀请
      },
      {
        path: "/myLink",  // 单页面 我的链接
        element: <MyLink />,
        auth: true,
      },
      {
        path: "/pageNoBg",  // 单页面 我的链接 发布资讯
        element: <PageNoBg />,
        auth: true,
      },
      {
        path: "/pageBg",  // 单页面 当前等级 算力矿池
        element: <PageBg />,
        auth: true,
      },
    ],
  },
];

const AppRouter = () => {
  const element = RouterGuard(routeConfig);
  return <Suspense> {element}</Suspense>;
};
export default AppRouter;
