import { useNavigate } from 'react-router-dom';
import left from '@/image/left.png'
const Return = (data: {
  path?: any;
  text: any;
}) => {
  const { path = -1, text } = data
  //初始化路由
  const navigate = useNavigate();
  // 路由跳转
  const go = () => {
    navigate(path)
  }
  return (
    <div className=''>
      <span onClick={go} className=' flex items-center  cursor-pointer '>
        <img className=' w-4 h-4  mr-1' src={left} alt="" />
        <div className=' font-medium text-sm' >{text} </div>
      </span>
    </div>
  )
}

export default Return