import { UpgradeProfitsType } from "@/interface";
import useContract from "@/store/useContract";
import useWallet from "@/store/useWallet";
import { useCallback, useEffect, useState } from "react";

const useGetLinkProfitsByLimit = () => {
  // 存放数据
  const [dataLinkProfits, setDataLinkProfits] = useState<UpgradeProfitsType[]>(
    []
  );
  // loading
  const [linkProfitsLod, setLoading] = useState(true);
  // 获取合约
  const {
    contract: { HistoricalOrders },
  } = useContract();
  // 页码 最开始是1000000000000000000，之后获取到最新的，需要进行更新
  const [pagesLinkProfits, setPages] = useState("1000000000000000000");
  // 获取
  const {
    wallet: { address },
  } = useWallet();
  // 清空所有数据，然后进行加载首页
  const first = () => {
    setPages("1000000000000000000");
    setDataLinkProfits([]);
    setLoading(true);
  };
  // 获取
  const setHistoryLinkProfits = () => {
    if (Number(pagesLinkProfits)) setLoading(true);
  };
  // 获取数据的方法
  const getDemandsByLimit = useCallback(async () => {
    if (HistoricalOrders && Number(pagesLinkProfits) && linkProfitsLod) {
      try {
        // 获取到数据
        const list = await HistoricalOrders.getLinkProfitsByLimit(
          address,
          pagesLinkProfits,
          1000
        );
        setDataLinkProfits(list)
        setPages("0");
        // // 修改页码，获取到得到数据的最后一条，为下一次开始的页码
        // if (list.length) {
        //   const id = Number(formatUnits(list[list.length - 1].id, 0)) - 1 || 0
        //   if (id <= 0) {
        //     setPages("0");
        //   } else {
        //     setPages(id.toString());
        //   }
        //   // 放入data
        //   setDataLinkProfits([...dataLinkProfits, ...list]);
        // } else {
        //   setPages("0");
        // }
      } catch (e) {
        console.log("useGetLinkProfitsByLimit", e);
      }
      setLoading(false);
    }
  }, [HistoricalOrders, address, linkProfitsLod, pagesLinkProfits]);

  useEffect(() => {
    getDemandsByLimit();
  }, [getDemandsByLimit]);
  return {
    dataLinkProfits,
    pagesLinkProfits,
    first,
    linkProfitsLod,
    setHistoryLinkProfits,
  };
};

export default useGetLinkProfitsByLimit;
