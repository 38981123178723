import { useEffect, useState } from 'react';
/*
    fn:执行的函数
    execute：是否可以执行
 */
const useNotFirstRender = (fn: Function, execute?: any, execute2?: any) => {
    // 用于表示是否是第一次渲染
    const [notFirstRender, setNotFirstRender] = useState(false);

    useEffect(() => {
        // 在第一次渲染时不执行逻辑
        if (!notFirstRender) {
            // 可以在这里进行一些初始化的操作
            // 更新状态，表示不再是第一次渲染
            setNotFirstRender(true);
        } else {
            // 从第二次渲染开始执行逻辑
            // 可以在这里放置你的其他逻辑
            if (execute) {
                fn()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [execute, execute2]);
}

export default useNotFirstRender;
