import { useEffect, useState } from "react"
import useGetChainID from "./useGetChainID"
import { chainParams } from "@/chain"

const useGetChainInfo = () => {
    // loading
    const [getGweiLod, setGetGweiLod] = useState(false)
    // 获取链id
    const { chainID } = useGetChainID()
    // 浏览器网站
    const [browser, setBrowser] = useState('')
    useEffect(() => {
        if (chainID) {
            setGetGweiLod(true)
            setBrowser(chainParams[chainID]?.blockExplorerUrls[0] || '')
            setGetGweiLod(false)
        }
    }, [chainID])
    return { browser, getGweiLod, }
}

export default useGetChainInfo