import { useCallback, useEffect } from "react";
// 页面触底加载
const useHandleScroll = (loading: boolean, fn: Function, isT: boolean = true, offsetHeight: number = 0) => {
    const handleScroll = useCallback((event: { target: any }) => {
        // 当页面滚动时触发
        if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - offsetHeight) {
            // 如果滚动条已经触底
            if (!loading) {
                fn();
            }
            // 在这里执行触底后的操作
        }
    }, [fn, loading, offsetHeight]);
    useEffect(() => {
        if (!isT) return
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll, isT])
}

export default useHandleScroll