import "./style/index.css";
import AppRouter from "@/router";
import useListenerNetWork from "@/web3Hooks/useListenerNetWork";
import useWatchWalletAddress from "@/web3Hooks/useWatchWalletAddress";
import { App, ConfigProvider } from "antd";
import useNewContract from "./web3Hooks/useNewContract";
import { useEffect } from "react";
import useConnectedWallet from "./web3Hooks/useConnectedWallet";
import useWallet from "./store/useWallet";
import useChangeBodyColor from "./hooks/useChangeBodyColor";
// import "@/log/index";
function MyApp() {
  useChangeBodyColor();
  // 存的缓存
  const {
    wallet: { walletName },
  } = useWallet();
  // 连接钱包的方法
  const { connected } = useConnectedWallet();
  // 监听网络
  useListenerNetWork();
  // 监听地址
  useWatchWalletAddress();
  // new 出合约
  useNewContract();
  // 在页面初始化的时候检测本地是都有缓存，有的话连接，没有的话不管
  useEffect(() => {
    if (walletName) {
      connected();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="MyApp">
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#2C363F",
          },
          components: {
            Menu: {
              horizontalItemSelectedColor: "#A7FF57", // 水平菜单项文字选中颜色	
              itemColor: '#FFFFFF', // 菜单项文字颜色
              itemHoverColor: "#A7FF57", // 菜单项文字悬浮颜色	
            },
            Input: {
              colorBgContainer: "#0B2C05", // 组件的容器背景色，例如：默认按钮、输入框等
              controlHeight: 48, // Ant Design 中按钮和输入框等基础控件的高度
              activeBorderColor: "#95DB0F", // 激活态边框色
              hoverBorderColor: "#95DB0F", // 悬浮态边框色
              colorBorder: "#95DB0F",  // 默认使用的边框颜色
              activeShadow: "", // 激活态阴影,
              colorText: '#fff',
              colorTextPlaceholder: "#AFAFAF"
            },
            Message: {
              contentBg: '#142012', // 浮层容器背景色
              colorTextHeading: '#FFFFFF',
              colorText: '#FFFFFF' // 最深的文本色
            },
            Notification: {
              colorBgElevated: '#142012', // 浮层容器背景色
              colorText: '#FFFFFF', // 最深的文本色
              colorTextHeading: '#FFFFFF'
            },
            Button: {
              controlHeight: 44, // Ant Design 中按钮和输入框等基础控件的高度
              contentFontSize: 14, // 按钮内容字体大小
              defaultBg: "linear-gradient(270deg, #1C8609 -31%, #A1C716 140%)", // 默认按钮背景色
              defaultColor: "#FFFFFF", // 默认按钮文本颜色
              defaultActiveColor: "#FFFFFF", // 默认按钮激活态文字颜色
              defaultActiveBg: "linear-gradient(270deg, #1C8609 -31%, #A1C716 140%)", // 默认按钮激活态背景色
              defaultActiveBorderColor: "linear-gradient(270deg, #1C8609 -31%, #A1C716 140%)", // 默认按钮激活态边框颜色
              defaultHoverColor: "#FFFFFF", // 默认按钮悬浮态文本颜色
              defaultHoverBg: "linear-gradient(270deg, #1C8609 -31%, #A1C716 140%)", // 默认按钮悬浮态背景色
              defaultHoverBorderColor: "", // 默认按钮悬浮态边框颜色
              defaultShadow: "", // 默认按钮阴影
              lineWidth: 0  // 	用于控制组件边框、分割线等的宽度
            },
            Tabs: {
              cardBg: "rgba(0, 0, 0, 0)",
              cardGutter: 9,
              horizontalItemPadding: "24px 0 12px 0",
              itemSelectedColor: "#FFFFFF", // 标签选中态文本颜色
              itemActiveColor: "#0A0A0A", // 标签激活态文本颜色
              itemHoverColor: "", // 标签悬浮态文本颜色
              itemColor: "#ADADAD", // 标签文本颜色
              inkBarColor: "#FFFFFF", // 指示条颜色
              colorBorderSecondary: "transparency", // 比默认使用的边框色要浅一级，此颜色和 colorSplit 的颜色一致。使用的是实色。
            },
            Select: {

              controlHeight: 48, // Ant Design 中按钮和输入框等基础控件的高度
              colorText: '#FFFFFF',
              selectorBg: '#0B2C05',  // 选框背景色	
              borderRadius: 6, // 基础组件的圆角大小，例如按钮、输入框、卡片等
              colorPrimary: "#95DB0F", // 主要色
              colorPrimaryHover: "", // 主色梯度下的悬浮态。
              colorBorder: "#3B5038", // 默认使用的边框颜色, 用于分割不同的元素，例如：表单的分割线、卡片的分割线等。
            },
            InputNumber: {
              colorBgContainer: "#0B2C05", // 组件的容器背景色，例如：默认按钮、输入框等
              controlHeight: 48, // Ant Design 中按钮和输入框等基础控件的高度
              activeBorderColor: "#95DB0F", // 激活态边框色
              hoverBorderColor: "#95DB0F", // 悬浮态边框色
              colorBorder: "#95DB0F",  // 默认使用的边框颜色
              activeShadow: "", // 激活态阴影,
              colorText: '#fff',
              colorTextPlaceholder: "#AFAFAF"
            },
            Form: {
              labelRequiredMarkColor: "#E46868", // 必填项标记颜色
              colorError: "#E46868", // 错误颜色
            },
            Timeline: {
              dotBg: "#A8ACA7", // 节点背景色
              tailColor: "#A8ACA7", // 轨迹颜色
              colorText: "#A8ACA7", // 最深的文本色
              fontSize: 12, // 设计系统中使用最广泛的字体大小
            },
            Modal: {
              contentBg: "#243024", // 内容区域背景色
              headerBg: "#243024", // 顶部背景色
              titleColor: "#FFFFFF", // 标题字体颜色
              colorIcon: "#898C88",//  控制弱操作图标的颜色，例如 allowClear 或 Alert 关闭按钮
            },
            Dropdown: {
              colorBgElevated: '#142012', // 浮层容器背景色
              colorText: '#AFAFAF' // 最深的文本色
            }
          },
        }}
      >
        <App>
          <AppRouter />
        </App>
      </ConfigProvider>
    </div>
  );
}

export default MyApp;
