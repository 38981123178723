//  白名单
export const chainIDArr = [12306];
// export const chainIDArr = [12307];
export const chainParams: any = {
    12306: {
        chainId: 12306,
        chainName: "Fibochain", // 自定义链的名称
        nativeCurrency: {
            name: "FIBO",
            symbol: "FIBO",
            decimals: 18,
        },
        rpcUrls: ["https://node1.fibo-rpc.asia"],
        blockExplorerUrls: ['https://scan.fibochain.org/'],
    },
    12307: {
        chainId: 12307,
        chainName: "FibochainTest", // 自定义链的名称
        nativeCurrency: {
            name: "FIBO",
            symbol: "FIBO",
            decimals: 18,
        },
        rpcUrls: ["https://node8.fibo-api.asia"],
        blockExplorerUrls: ['https://main.hzroc.art/'],
    },
    65: {
        chainId: 65,
        chainName: "OKCTestnet", // 自定义链的名称
        nativeCurrency: {
            name: "OKT",
            symbol: "OKT",
            decimals: 18,
        },
        rpcUrls: ["https://exchaintestrpc.okex.org"],
        blockExplorerUrls: ['https://www.oklink.com/cn/oktc-test/'],
    },
};