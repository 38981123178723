import empty from "@/image/empty.png";
import { useTranslation } from "react-i18next";
const Empty = () => {
  const { t } = useTranslation();
  return (
    <div className=" pb-4 flex flex-col items-center">
      <img className=" w-14 h-14" src={empty} alt="" />
      <p className="text-[12px] text-white ">{t('components.noData')}</p>
    </div>
  );
};

export default Empty;
