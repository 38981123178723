import FTContact from "./FTabi";
import FContact from "./Fabi";
import OContact from "./Oabi";
type numberAny = {
  [key: number]: any;
};
const contract: numberAny = {
  12306: FContact,
  12307: FTContact,
};
export default contract;
