import { useState } from "react";
import useContract from "@/store/useContract";
import useListenerTransfer from "./useListenerTransfer";
import useNotification from "@/hooks/useNotification";
import { useTranslation } from "react-i18next";

/**
 * @description: 质押，增加合约算力，stake(uint256 _amount, uint256 _unlockCycle) 其中 _amount: 质押的金额 _unlockCycle: 质押的周期
 * 要求:
 *      用户是已经注册过的。
        质押金额需要是100的倍数。
        质押金额需要在100和30000之间。
        质押周期只能是15、30、90、180、360天对应的时间戳。
        如果质押周期为15天，则金额不能超过1000。30天，金额不超过5000。90天，金额不超过10000。180天，金额不超过20000。360天，金额不超过30000。
 * @return {*}
 */
export default function useStake(fn: {
  successFn?: Function;
  errorFn?: Function;
}) {
  const { successFn, errorFn } = fn;
  const { t } = useTranslation();
  /**合约 */
  const {
    contract: { FFR },
  } = useContract();
  /**提示 */
  const { showNotification } = useNotification();
  /**loading */
  const [loading, setLoading] = useState(false);
  // 获取监听事件成功的方法
  const listenerTransferF = useListenerTransfer();
  /**
   * @description: 触发器
   * @return {*}
   */
  const stakeTrigger = async (amount: bigint, unlockCycle: number) => {
    setLoading(true);
    if (FFR) {
      try {
        const { hash } = await FFR.stake(amount, unlockCycle, {
          value: amount,
        });
        // 等待交易完成
        const relset = await listenerTransferF(hash);
        if (relset) {
          // 当交易成功时
          // 提示
          showNotification(
            "success",
            {
              message: t("hooks.successfulPledge"),
              description: t("hooks.goToTheBlockBrowser"),
            },
            hash
          );
          // 成功后调用成功函数
          successFn && successFn();
        } else {
          showNotification(
            "error",
            {
              message: t("hooks.failureOfPledge"),
              description: t("hooks.goToTheBlockBrowser"),
            },
            hash
          );
          // 失败后调用失败函数
          errorFn && errorFn();
        }
      } catch (error) {
        console.error(error);
        // 失败后调用失败函数
        errorFn && errorFn();
      } finally {
        setLoading(false);
      }
    }
  };

  return {
    stakeTrigger,
    stakeLoading: loading,
  };
}
