// 当天的质押量 当天的质押限制量

import useContract from "@/store/useContract"
import { useCallback, useEffect, useState } from "react"

const useGetCurDayStakeLimit = () => {
    // 获取合约
    const { contract: { FFR } } = useContract()
    // loading
    const [curDayStakeLimitLod, setLoading] = useState(false)
    const [stakeDate, setStakeDate] = useState({
        pledgedDay: BigInt(0), // 当天的质押量
        stakeLimit: BigInt(0) // 质押限制量
    })
    const getCurDayStakeLimit = useCallback(async () => {
        setLoading(true)
        try {
            if (FFR) {
                const res = await FFR.getCurDayStakeLimit()
                setStakeDate({
                    pledgedDay: res[2],
                    stakeLimit: res[3]
                })
            }
        } catch (e) {
            console.log('useGetUserInfo', e);
        }
        setLoading(false)
    }, [FFR])
    useEffect(() => {
        getCurDayStakeLimit()
    }, [getCurDayStakeLimit])
    return { curDayStakeLimitLod, getCurDayStakeLimit, stakeDate }
}

export default useGetCurDayStakeLimit