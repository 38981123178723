import { useState } from "react";
import useContract from "@/store/useContract";

export interface StakeBill {
  id: bigint; // 订单ID
  initialAmount: bigint; // 订单初始金额
  amount: bigint; // 金额
  totalReward: bigint; // 累计提取收益
  startTime: bigint; // 订单开始时间
  endTime: bigint; // 订单结束时间
  lastTime: bigint; // 最近一次提取时间
  monthlyRate: bigint; // 月利率
  unlockCycle: bigint; // 解锁周期
  multiple: bigint; // 算力矿池倍数
  pool: bigint; // 算力矿池余额
  isPrivate: boolean; // 是否是私募订单
}
export default function useGetStakeByLimit() {
  /**合约 */
  const {
    contract: { FFR },
  } = useContract();

  /**loading */
  const [loading, setLoading] = useState(false);
  /**账单列表 */
  const [stakeBills, setStakeBills] = useState<StakeBill[]>([]);

  /**
   * @description: 合约算力获取订单信息抓取器
   * @param {string} userAddress 用户地址
   * @param {number} startId 开始订单
   * @param {number} limit 获取多少条数据
   * @return {*}
   */
  const getStakeByLimitFetcher = async (
    userAddress: string,
    startId: number | string,
    limit: number
  ) => {
    if (!FFR) return;

    try {
      setLoading(true);
      const res = await FFR.getStakeByLimit(userAddress, startId, limit);

      // 为了适配滑动分页
      if (startId === "1000000000000000000") {
        setStakeBills(res);
      } else {
        setStakeBills([...stakeBills, ...res]);
      }

      return res;
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    getStakeByLimitFetcher,
    getStakeByLimitLoading: loading,
    stakeBills,
  };
}
