import useWallet from "@/store/useWallet";
import walletimg from "@/image/walletimg.png";
import downarrow from "@/image/downarrow.png";
import copy from "@/image/copy.png";
import href from "@/image/href.png";
import switchImg from "@/image/switchImg.png";
import useConnectedWallet from "@/web3Hooks/useConnectedWallet";
import { useState } from "react";
import { Button, Dropdown, Menu, MenuProps, Modal } from "antd";
import { cheengeNumber, mobileHidden } from "@/utils";
import useAddressConvert from "@/hooks/useAddressConvert";
import useHandleCopyClick from "@/hooks/useHandleCopyClick";
import { useLocation, useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import useMainNetworkCoin from "@/web3Hooks/useMainNetworkCoin";
import { formatUnits } from "ethers";
import useAddressType from "@/store/useAddressType";
import FFRLogo from "@/image/FFRLogo.svg";
import languageImg from "@/image/languageImg.png";
import { useTranslation } from "react-i18next";
import useLanguage from "@/store/useLanguage";
import tabHome from '@/image/tabHome.png'
import tabFlowHash from '@/image/tabFlowHash.png'
import tabContractHash from '@/image/tabContractHash.png'
import tabLinkHash from '@/image/tabLinkHash.png'
import tabMy from '@/image/tabMy.png'
import useHasReferrer from "@/web3Hooks/useHasReferrer";

const languageItems: MenuProps["items"] = [
  {
    key: "cn",
    label: "中文",
  },
  {
    key: "en",
    label: "English",
  },
  {
    key: "ja",
    label: "わぶん",
  },
  {
    key: "ko",
    label: "한국어",
  },
]
const Header = () => {
  const { setLanguage } = useLanguage()
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation()
  useHasReferrer();
  // 地址类型
  const { setAddressType, addressType } = useAddressType();
  // 获取主网币余额
  const { balanceLod, balance, getMainNetworkCoin } = useMainNetworkCoin(false);
  // 获取钱包地址
  const {
    wallet: { address },
  } = useWallet();
  // 连接，断开钱包函数
  const { connected, breaks } = useConnectedWallet();
  // 地址转换
  const { addressConvert } = useAddressConvert();
  // 复制
  const { handleCopyClick } = useHandleCopyClick();
  // 初始化路由
  const navigate = useNavigate();
  // 控制弹窗的参数
  const [isModalOpen, setIsModalOpen] = useState(false);
  // 打开弹窗
  const showModal = () => {
    setIsModalOpen(true);
    // 每次打开弹窗时回去主网币余额
    getMainNetworkCoin();
  };
  // 关闭弹窗
  const handleOk = () => {
    setIsModalOpen(false);
  };
  // 去某个网站
  const goHref = (url: string) => {
    return () => {
      window.open(url);
    };
  };
  // 地址转换
  const changeAddressType = () => {
    const type = addressType === "0x" ? "fb" : "0x";
    setAddressType(type);
  };
  const onClick: MenuProps['onClick'] = (e) => {
    navigate(e.key);
  };
  // 改变语言
  const changeLanguage: MenuProps["onClick"] = ({ key }) => {
    i18n.changeLanguage(key);
    setLanguage(key)
  };
  const items: MenuProps['items'] = [
    {
      label: <div className="flex items-center">
        <img className="w-5 h-5 mr-2" src={tabHome} alt="" />   {t('foot.homePage')}
      </div>,
      key: '/',
    }, {
      label: <div className="flex items-center">
        <img className="w-5 h-5 mr-2" src={tabFlowHash} alt="" /> {t('foot.flowHashrate')}
      </div>,
      key: '/flowHash',
    }, {
      label: <div className="flex items-center">
        <img className="w-5 h-5 mr-2" src={tabContractHash} alt="" />  {t('foot.contractHashrate')}
      </div>,
      key: '/contractHash',
    }, {
      label: <div className="flex items-center">
        <img className="w-5 h-5 mr-2" src={tabLinkHash} alt="" />  {t('foot.linkHashrate')}
      </div>,
      key: '/linkHash',
    }, {
      label: <div className="flex items-center">
        <img className="w-5 h-5 mr-2" src={tabMy} alt="" />  {t('foot.my')}
      </div>,
      key: '/my',
    }
  ];
  return (
    <div className=" bg-black16 flex items-center justify-between box-border px-4.5 py-3.5 border-b-1 border-[#3F524A]">
      <div className=" flex items-center	">
        <img
          onClick={() => { navigate("/") }}
          className="w-7.5 h-7.5 rounded-full mr-2.5 cursor-pointer"
          src={FFRLogo}
          alt=""
        />
        <span className="text-white text-lg font-bold">FFR</span>
        <Menu className=" hidden min-w-[560px] ml-4 bg-transparent md:block" onClick={onClick} selectedKeys={[pathname]} mode="horizontal" items={items} />
      </div>
      <div className=" flex items-center">
        <Dropdown
          trigger={['click']}
          menu={{ items: languageItems, onClick: changeLanguage }}
          placement="bottomRight"
          arrow
        >
          <div className=" flex items-center border-1 border-green435 p-1 rounded-md mr-3">
            <img src={languageImg} className=" w-6 h-6 mr-1 cursor-pointer" alt="" />
            <img className="w-3 h-3" src={downarrow} alt="" />
          </div>
        </Dropdown>
        {address ? (
          <div className="flex items-center">
            <Button
              className="  flex items-center justify-between rounded-md px-3  w-16 h-8.5"
              onClick={showModal}
            >
              <img className="w-4 h-4 mr-3" src={walletimg} alt="" />
              <img className="w-3 h-3" src={downarrow} alt="" />
            </Button>
          </div>
        ) : (
          <Button
            className=" rounded-md h-8.5 text-sm  flex items-center justify-center text-white "
            onClick={() => {
              connected();
            }}
          >
            {t('header.connectWallet')}
          </Button>
        )}
      </div>
      <Modal
        footer={null}
        title={t('header.myWallet')}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleOk}
      >
        <div className=" pt-5 whitespace-nowrap text-white">
          <div className="flex items-center justify-between ">
            <div className="flex items-center ">
              <span className=" text-sm mr-2  text-grayA8 ">{t('header.walletAddress')}</span>
              <img
                className=" w-4 h-4 cursor-pointer"
                onClick={changeAddressType}
                src={switchImg}
                alt=""
              />
            </div>
            <div>
              <span className=" text-xs text-grayA8   ">FIBO{t('header.balance')}：</span>
              <span className="text-sm text-black3">
                {balanceLod ? (
                  <LoadingOutlined />
                ) : (
                  cheengeNumber(formatUnits(balance), 4)
                )}
              </span>
            </div>
          </div>
          <div className=" box-border h-11.5 mt-4 flex items-center justify-between bg-green33 py-4 px-3 rounded-md ">
            <span>
              {mobileHidden(addressConvert(address), 25, 5)}
            </span>
            <img
              className="w-4 h-4 ml-2 cursor-pointer"
              onClick={() => {
                handleCopyClick(addressConvert(address));
              }}
              src={copy}
              alt=""
            />
          </div>
          <Button
            className="mt-3"
            block
            onClick={() => {
              breaks();
              handleOk();
              navigate("/");
            }}
          >
            {t('header.exitWallet')}
          </Button>
          <div
            className="flex items-center justify-center mt-3 cursor-pointer"
            onClick={goHref("https://scan.fibochain.org/")}
          >
            <span className=" text-grayA8 text-xs">View on Testfiboscan</span>
            <img className="w-3 h-3 ml-2" src={href} alt="" />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Header;
