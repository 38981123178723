import Empty from "@/components/Empty"
import NoDataText from "@/components/NoDataText"
import useHandleScroll from "@/hooks/useHandleScroll"
import { GetDemandsType } from "@/interface"
import { formatNumber, formatTimeToStr } from "@/utils"
import useFlowClaim from "@/web3Hooks/useFlowClaim"
import useFlowWithdraw from "@/web3Hooks/useFlowWithdraw"
import useGetDemandsByLimit from "@/web3Hooks/useGetDemandsByLimit"
import useGetDemands from "@/web3Hooks/useGetdemands"
import useUnextractedEarnings from "@/web3Hooks/useUnextractedEarnings"
import { LoadingOutlined } from "@ant-design/icons"
import { Button, Tooltip } from "antd"
import { formatUnits } from "ethers"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

const History = () => {
    const { t } = useTranslation();
    // 获取订单
    const { data, pages, historyLod, setHistory } = useGetDemandsByLimit(20)
    // 触底加载
    useHandleScroll(historyLod, setHistory, undefined, 0)
    return (
        <>
            <div className="box-border px-2.5 mt-2.25 mx-2.5 bg-green27  rounded-lg overflow-hidden md:max-w-300 md:mt-5 md:mx-auto  md:flex md:flex-wrap md:justify-center">
                {data.map((item, index) => {
                    return <div key={index} className=" border-t-1 border-gray38 first:border-t-0 md:w-96 md:mx-1 md:first:border-1  md:border-green-500 md:my-4 md:p-4 md:shadow-md md:rounded-xl md:border-1">
                        <HistoryItem item={item} />
                    </div>
                })}
            </div>
            <div className=" flex justify-center pt-2">
                {historyLod && <span className="text-white md:text-sl"><LoadingOutlined /> {t('loading')}
                </span>}
                {!data.length && !historyLod && !Number(pages) && <Empty />}
                {!Number(pages) && !!data.length && <div className=" text-xs text-gray7"><NoDataText />
                </div>}
            </div>
        </>
    )
}



const HistoryItem = (props: { item: GetDemandsType }) => {
    const { t } = useTranslation()
    const { item } = props
    // 真正使用的数据
    const [data, setData] = useState(item)
    // 获取账单的未提取收益
    const { unextractedEarnings, unextractedEarningsBalanceLod, getUnextractedEarnings } = useUnextractedEarnings()
    // 单独获取数据
    const { demandsLod, getDemands } = useGetDemands()
    // 更新
    const update = () => {
        // 单独获取账单数据
        getDemands(item.id).then((res) => {
            setData(res)
        })
        // 获取账单未提取收益
        getUnextractedEarnings(item.id)
    }
    // 提取收益
    const { getFlowClaim, flowClaimLod } = useFlowClaim(update)
    // 赎回
    const { getFlowWithdraw, flowWithdrawLod } = useFlowWithdraw(update)
    useEffect(() => {
        getUnextractedEarnings(item.id)
    }, [getUnextractedEarnings, item.id])
    return <div className="relative box-border px-2 pt-17 pb-6">
        <div className=" flex items-center justify-center rounded-e-xl absolute top-4 left-[-10px] w-17.75 h-6 font-normal text-xs text-black3  bg-green37 text-grayAD px-3 ">
            ID：{Number(formatUnits(data.id, 0)) + 1}
        </div>
        <div className=" flex items-center justify-between mb-6">
            <span className=" font-light text-grayA8 text-xs1">{t('flow.stakingAmount')}</span>
            <span className=" text-xs1 font-medium text-white">{demandsLod ? <LoadingOutlined /> : formatUnits(data.amount, 18)} FIBO</span>
        </div>
        <div className="flex items-center justify-between  mb-6">
            <span className=" font-light text-grayA8 text-xs1">{t('flow.cumulativeWithdrawalIncome')}</span>
            <Tooltip placement="topLeft" title={formatUnits(data.totalReward)} arrow>
                <span className=" text-xs1 font-medium text-white cursor-pointer">+{demandsLod ? <LoadingOutlined /> : formatNumber(formatUnits(data.totalReward))}FIBO</span>
            </Tooltip>
        </div>
        <div className=" flex items-center justify-between  mb-6">
            <span className=" font-light  text-xs1 text-green-600">{t('flow.unattractedEarnings')}</span>
            <Tooltip placement="topLeft" title={formatUnits(unextractedEarnings)} arrow>
                <span className=" text-xs1 font-medium text-white cursor-pointer">{unextractedEarningsBalanceLod ? <LoadingOutlined /> : formatNumber(formatUnits(unextractedEarnings))}FIBO</span>
            </Tooltip>
        </div>
        <div className=" flex items-center justify-between  mb-6">
            <span className=" font-light text-grayA8 text-xs1">{t('flow.timeOfPledge')}</span>
            <span className=" text-xs1 font-medium text-white">{demandsLod ? <LoadingOutlined /> : formatTimeToStr(Number(formatUnits(data.startTime, 0)) * 1000)}</span>
        </div>
        {data.isWithdrawn ?
            <Button style={{ background: 'transparent' }}
                className="  text-green74 border border-green74" block >{t('flow.redeemed')}</Button>
            :
            <div className=" flex items-center justify-between">
                <Button disabled={demandsLod} style={{ background: 'transparent' }}
                    className=" w-35 text-green74 border border-green74" loading={flowWithdrawLod} onClick={() => getFlowWithdraw(data.id)}>{t('flow.redemption')}</Button>
                <Button disabled={demandsLod} className="  w-35" loading={flowClaimLod} onClick={() => getFlowClaim(data.id)} >{t('flow.extractingEarnings')}</Button>
            </div>
        }
    </div >
}
export default History