import useContract from "@/store/useContract";
import useListenerTransfer from "./useListenerTransfer";
import { useState } from "react";
import useNotification from "@/hooks/useNotification";
import { useTranslation } from "react-i18next";

const useRegister = (successFn?: Function, errorFn?: Function) => {
  const { t } = useTranslation();
  // 获取合约
  const {
    contract: { FFR },
  } = useContract();
  // 提示
  const { showNotification } = useNotification();
  // laoding
  const [registerLod, setLoading] = useState(false);
  // 获取监听事件成功的方法
  const listenerTransferF = useListenerTransfer();
  // 注册函数
  const register = async (referral: string) => {
    setLoading(true);
    if (FFR) {
      try {
        const { hash } = await FFR.register(referral);
        // 查询交易
        const result = await listenerTransferF(hash);
        if (result) {
          // 当交易成功时
          // 提示
          showNotification(
            "success",
            {
              message: t("hooks.registeredSuccessfully"),
              description: t("hooks.goToTheBlockBrowser"),
            },
            hash
          );
          // 成功后调用成功函数
          successFn && successFn();
        } else {
          showNotification(
            "error",
            {
              message: t("hooks.registeredFailed"),
              description: t("hooks.goToTheBlockBrowser"),
            },
            hash
          );
          // 失败后调用失败函数
          errorFn && errorFn();
        }
      } catch (e) {
        console.log("useRegister", e);
      }
      setLoading(false);
    }
  };
  return { register, registerLod };
};

export default useRegister;
