import { GetDemandsType } from "@/interface";
import useContract from "@/store/useContract";
import useWallet from "@/store/useWallet";
import { formatUnits } from "ethers";
import { useCallback, useEffect, useState } from "react";

const useGetDemandsByLimit = (seize: number = 10) => {
    // 存放数据
    const [data, setData] = useState<GetDemandsType[]>([])
    // loading
    const [historyLod, setLoading] = useState(true)
    // 获取合约
    const { contract: { FlowDeposit } } = useContract()
    // 页码 最开始是1000000000000000000，之后获取到最新的，需要进行更新
    const [pages, setPages] = useState('1000000000000000000')
    // 获取
    const { wallet: { address } } = useWallet()
    // 清空所有数据，然后进行加载首页
    const first = () => {
        setPages('1000000000000000000')
        setData([])
        setLoading(true)
    }
    // 获取
    const setHistory = () => {
        if (Number(pages)) setLoading(true)
    }
    // 获取数据的方法
    const getDemandsByLimit = useCallback(async () => {
        if (FlowDeposit && Number(pages) && historyLod) {
            try {
                // 获取到数据
                const list = await FlowDeposit.getDemandsByLimit(address, pages, seize)
                // 修改页码，获取到得到数据的最后一条，为下一次开始的页码
                if (list.length) {
                    const id = Number(formatUnits(list[list.length - 1].id, 0)) - 1 || 0
                    if (id <= 0) {
                        setPages('0')
                    } else {
                        setPages(id.toString())
                    }
                    // 放入data
                    setData([...data, ...list])
                } else {
                    setPages('0')
                }
            } catch (e) {
                console.log('useGetDemands', e);
            }
            setLoading(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [FlowDeposit, address, data, historyLod, pages])
    useEffect(() => {
        getDemandsByLimit()
    }, [getDemandsByLimit])
    return { data, pages, first, historyLod, setHistory, }
}

export default useGetDemandsByLimit