import { Interface, InterfaceAbi } from "ethers";
const FFRAbi = require("./josn/FFR.json");
const FlowDepositAbi = require("./josn/FlowDeposit.json");
const HistoricalOrdersAbi = require("./josn/HistoricalOrders.json");
type objKeyObjectType = {
  [key: string]: { address: string; abi: Interface | InterfaceAbi };
};
let FContact: objKeyObjectType = {
  FFR: { abi: FFRAbi, address: "" },
  FlowDeposit: { abi: FlowDepositAbi, address: "" },
  HistoricalOrders: { abi: HistoricalOrdersAbi, address: "" },
};
console.log("环境", process.env.REACT_APP_ENV);
if (process.env.REACT_APP_ENV === "development") {
  //开发环境
  FContact.FFR.address = "0x47f00156f5a27464b15eda99cf7f8eaf742ff83e";
  FContact.FlowDeposit.address = "0x3ad922e4e6d6458f7f99aed3fb92176fb36afbdd";
  FContact.HistoricalOrders.address =
    "0x5c527d9d1229164ae2af91612100c5c8b49be57c";
} else if (process.env.REACT_APP_ENV === "test") {
  // 测试环境
  FContact.FFR.address = "";
  FContact.FlowDeposit.address = "";
  FContact.HistoricalOrders.address = "";
} else {
  //生产环境
  FContact.FFR.address = "0x47f00156f5a27464b15eda99cf7f8eaf742ff83e";
  FContact.FlowDeposit.address = "0x3ad922e4e6d6458f7f99aed3fb92176fb36afbdd";
  FContact.HistoricalOrders.address =
    "0x5c527d9d1229164ae2af91612100c5c8b49be57c";
}
export default FContact;
