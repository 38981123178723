import { LoadingOutlined } from "@ant-design/icons";
import { truncateDecimals } from "@/utils";

interface InfoProps {
  icon: string;
  title: string;
  value: string | number;
  symbol?: string;
  className?: string;
  className1?: string;
  className2?: string;
  loading?: boolean;
}

export default function Info(props: InfoProps) {
  const { icon, value, symbol, title, className, loading, className2, className1 } = props;

  /**
   * @description: 数据默认值 如果没有值则展示 --
   * @param {string | number} value 数据源
   * @return {*}
   */
  const defaultValue = (value: string | number) => {
    if (parseFloat(value.toString())) {
      return value;
    } else {
      return "--";
    }
  };

  return (
    <div className={`flex flex-col ${className}`}>
      <div className={`flex items-center ${className1}`}>
        <img className=" w-3.5 h-3.5" src={icon} alt="" />
        <span className={` ml-1.5 text-xs text-textColor-gray font-light `}>
          {title}
        </span>
      </div>
      <div className={`flex items-center mt-2.5 ${className2}`}>
        <span>
          {loading ? (
            <LoadingOutlined />
          ) : (
            defaultValue(parseFloat(truncateDecimals(value.toString(), 2)))
          )}
        </span>

        {symbol && <span className=" ml-1">{symbol}</span>}
      </div>
    </div>
  );
}
