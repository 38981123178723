import Return from '@/components/Return'
import { formatNumber } from '@/utils';
import useGetUserInfo from '@/web3Hooks/useGetUserInfo';
import { LoadingOutlined } from '@ant-design/icons';
import { formatUnits } from 'ethers';
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom';
import titleImg from '@/image/titleImg.png'
import D0 from '@/image/D0.png'
import D1 from '@/image/D1.png'
import D2 from '@/image/D2.png'
import D3 from '@/image/D3.png'
import D4 from '@/image/D4.png'
import D5 from '@/image/D5.png'
import { Tooltip } from 'antd';
const img = [D0, D1, D2, D3, D4, D5]
const PageBg = () => {
    const { t } = useTranslation()
    const [searchParams] = useSearchParams();
    const id = searchParams.get('id');
    const returnData = [t('pageBg.miningPool'),
    <div className=' flex'>
        {t('pageBg.currentLevel')} <div onClick={(e) => { e.stopPropagation() }}>
            <Tooltip trigger={['click']} color="#FFFFFF" placement="rightTop"
                title={<div className="w-32 text-black text-xs flex items-center flex-wrap">
                    {img.map((item, index) => {
                        return <div key={index} className=' font-bold flex items-center mr-1 mb-1'><img src={item} alt="" className="w-[17px] h-[14px]" />D{index}{t('pageBg.grade')}</div>
                    })}
                </div>} >
                <img src={titleImg} className=" w-5 h-5" alt="" />
            </Tooltip>
        </div>
    </div>
    ]
    // 获取用户的信息
    const { userInfo, userInfoLod } = useGetUserInfo();
    return (
        <div className=' max-w-300 mx-auto'>
            <div className=" h-16 flex items-center pl-3.5 text-white bg-green23 md:hidden ">
                <Return text={returnData[Number(id)]} />
            </div>
            {
                id === '0' &&
                <div style={{
                    background: 'rgba(42, 88, 51, 0.94)', backdropFilter: 'blur(2px)',
                    boxShadow: '0px -7px 23px 0px rgba(40, 228, 143, 0.0),0px 15px 30px 0px rgba(0, 0, 0, 0.3),inset -4px -4px 8px 0px rgba(0, 0, 0, 0.4),inset 4px 4px 8px 0px rgba(255, 255, 255, 0.26)'
                }} className='text-white flex flex-col items-center justify-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[216px] h-[141px] rounded'>
                    <p className=' text-sm pb-4'>{t('pageBg.theCurrentTotalPowerMiningPool')}</p>
                    <p className=" text-lg font-bold">
                        {userInfoLod ? (
                            <LoadingOutlined />
                        ) : (
                            formatNumber(formatUnits(userInfo?.poolBalance))
                        )}{" "}
                        FIBO
                    </p>
                </div>
            }
            {
                id === '1' &&
                <div style={{
                    background: 'rgba(42, 88, 51, 0.94)', backdropFilter: 'blur(2px)',
                    boxShadow: '0px -7px 23px 0px rgba(40, 228, 143, 0.0),0px 15px 30px 0px rgba(0, 0, 0, 0.3),inset -4px -4px 8px 0px rgba(0, 0, 0, 0.4),inset 4px 4px 8px 0px rgba(255, 255, 255, 0.26)'
                }} className='text-white pt-4 pl-4 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[216px] h-[141px] rounded'>
                    <p className=' pb-4 text-xs'>{t('pageBg.presentRate')}</p>
                    <div className=' flex items-center justify-center font-bold text-[22px]'>
                        {userInfoLod ? (
                            <LoadingOutlined />
                        ) : (
                            <>
                                <img className='w-[37px] h-[30px] mr-2' src={img[Number(formatUnits(userInfo?.curLevel, 0))]} alt="" />
                                D{Number(formatUnits(userInfo?.curLevel, 0))}
                            </>
                        )}
                    </div>
                    <div className=' flex items-center justify-center mt-[11px]  text-[#A1AFA4]'>
                        <div className=' border-1 px-1 border-[#346C40]'>
                            {t('pageBg.allTimeHigh')} D{userInfoLod ? (
                                <LoadingOutlined />
                            ) : (
                                Number(formatUnits(userInfo?.bestLevel, 0))
                            )}
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default PageBg