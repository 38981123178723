import { useCallback, useEffect, useRef } from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import Empty from "@/components/Empty";

interface TableProps {
  columns: {
    title: string;
    dataIndex: string;
    render?: (td: any, tr: any) => React.ReactNode;
  }[];
  dataSource: any[];
  loadMore?: Function;
  loading?: boolean;
}

export default function Tables(props: TableProps) {
  const { columns, dataSource, loadMore, loading } = props;
  /**容器Ref对象 */
  const containerRef = useRef<HTMLDivElement | null>(null);

  /**
   * @description: 监听滚动条事件处理函数
   * @param {Event} event
   * @return {*}
   */
  const handleScroll = useCallback(
    (event: Event) => {
      if (typeof loading === "undefined") return;

      if (loading) return;

      const target = event.target as HTMLDivElement;
      // 获取当前滚动高度
      const scrollTop = target.scrollTop;
      // 获取内容区域的总高度
      const scrollHeight = target.scrollHeight;
      // 获取容器的高度（可视区域高度）
      const clientHeight = target.clientHeight;

      // 判断是否滚动到底部
      if (scrollTop + clientHeight >= scrollHeight) {
        loadMore && loadMore(dataSource);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataSource]
  );

  useEffect(() => {
    // 检查引用是否已挂载到DOM元素上
    const container = containerRef.current;
    if (container && dataSource.length) {
      // 添加滚动事件监听器
      container.addEventListener("scroll", handleScroll);

      // 清理函数：在组件卸载时移除滚动事件监听器
      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef, dataSource]);

  return (
    <Spin tip="Loading..." indicator={<LoadingOutlined />} spinning={loading}>
      <div
        ref={containerRef}
        className="w-full border border-gray2F rounded-lg text-white overflow-hidden h-[320px] overflow-y-scroll relative"
      >
        <table className="w-full">
          <thead className="w-full h-10.5 bg-green16">
            <tr>
              {columns.map((columns) => (
                <th
                  key={columns.dataIndex}
                  className=" text-[12px] font-light text-textColor-gray"
                >
                  {columns.title}
                </th>
              ))}
            </tr>
          </thead>

          {dataSource.length ? (
            <tbody>
              {dataSource?.map((tr, index) => {
                return (
                  <tr className="w-full h-10" key={index}>
                    {columns.map((td) => {
                      return (
                        <td
                          key={td.dataIndex}
                          className="h-full text-center text-[12px]  font-medium"
                        >
                          {td.render ? (
                            td.render(tr[td.dataIndex], tr)
                          ) : (
                            <span>{tr[td.dataIndex]}</span>
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <div className=" w-full h-[276px] flex items-center justify-center absolute">
              <Empty />
            </div>
          )}
        </table>
      </div>
    </Spin>
  );
}
