import { ethers } from 'ethers'
import { download, walletNameList, walletNameListShow } from "@/walletName"
import useWallet from "@/store/useWallet"

const useConnectedWallet = () => {
    const { setWallet, wallet, clearWallet } = useWallet()
    let connected = (walletName = 'MetaMask') => connectedWallet(walletName);
    let breaks = () => breakWallet()
    // 连接钱包
    const connectedWallet = async (walletName: string) => {
        try {
            // 判断有没有选中的钱包
            // eslint-disable-next-line no-eval
            if (eval(`window.${walletNameListShow[walletName]}`)) {
                // 有的话连接钱包
                const provider = new ethers.BrowserProvider(
                    // eslint-disable-next-line no-eval
                    eval(`window.${walletNameList[walletName]}`)
                );
                const signer = await provider.getSigner();
                const address = signer.address;
                // 本地没有连接过的钱包的话，存入本地，有的话不存
                localStorage.setItem('walletName', walletName)
                // 本地有连接过的钱包的话，状态管理的walletName不用更新，如果没有的话更新
                wallet.walletName ? setWallet({ ...wallet, signer, address, provider }) : setWallet({ ...wallet, signer, address, provider, walletName })
            } else {
                window.open(download[walletName]);
            }
        } catch (e) {
            console.log('e', e);
        }
    }
    // 断开钱包
    const breakWallet = () => {
        console.log('断开钱包');
        // 断开钱包先清除本地的的连接的钱包名称
        localStorage.removeItem('walletName')
        // 然后清除状态管理的参数
        clearWallet()
    }
    return { connected, breaks }
}
export default useConnectedWallet
