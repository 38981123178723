import { App } from "antd";
import { useTranslation } from "react-i18next";
const useHandleCopyClick = () => {
    const { t } = useTranslation();
    const { message } = App.useApp();
    // 复制
    const handleCopyClick = (data: string | number) => {
        try {
            data = data + "";
            const textField = document.createElement("textarea");
            textField.innerText = data;
            document.body.appendChild(textField);
            textField.select();
            document.execCommand("copy");
            textField.remove();
            message.success(t('hooks.copySuccessfully'));
        } catch (e) {
            message.error(t('hooks.copyFailed'));

        }
    };
    return { handleCopyClick }
}

export default useHandleCopyClick