import { Input, Select, Drawer, Button, Form } from "antd";
import { useEffect, useState } from "react";
import useStake from "@/web3Hooks/useStake";
import { ethers, formatEther, formatUnits } from "ethers";
import CloseIcon from "@/image/close.png";
import { LoadingOutlined } from "@ant-design/icons";
import useMainNetworkCoin from "@/web3Hooks/useMainNetworkCoin";
import SelectSuffixIcon from "@/image/select-suffixIcon.png";
import useGetUserInfo from "@/web3Hooks/useGetUserInfo";
import useNotFirstRender from "@/hooks/useNotFirstRender";
import { useTranslation } from "react-i18next";
import blanceImg from '@/image/blanceImg.png'
import { formatNumber } from "@/utils";
import useGetCurDayStakeLimit from "@/web3Hooks/useGetCurDayStakeLimit";

interface SelectList {
  label: string;
  value: number;
  coreCount: string;
  multiple: string;
  min: number;
  max: number;
}

interface PledgeProps {
  getStakeByLimitFetcher: (
    userAddress: string,
    startId: string | number,
    limit: number
  ) => Promise<void>;

  address: string;
}

/**
 * @description: 合约质押组件
 * @return {*}
 */
export default function Pledge({
  getStakeByLimitFetcher,
  address,
}: PledgeProps) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { userInfo, userInfoLod } = useGetUserInfo();

  // 获取主网币余额
  const { balanceLod, balance } = useMainNetworkCoin();
  /**select选择框抽屉开关 */
  const [selectDrawerOpen, setSelectDrawerOpen] = useState(false);
  /**amount数量 */
  const [amount, setAmount] = useState("");
  /**select选择项 */
  const [selectActive, setSelectActive] = useState(15);
  /* 获取当前质押量和质押总量 */
  const { curDayStakeLimitLod, getCurDayStakeLimit, stakeDate } = useGetCurDayStakeLimit()
  /**select列表 */
  const selectList: SelectList[] = [
    {
      label: `15${t("contract.day")}`,
      value: 15,
      coreCount: "2.0T",
      multiple: "1.5",
      min: 100,
      max: 1000,
    },
    {
      label: `30${t("contract.day")}`,
      value: 30,
      coreCount: "4.0T",
      multiple: "2.0",
      min: 100,
      max: 5000,
    },
    {
      label: `90${t("contract.day")}`,
      value: 90,
      coreCount: "6.0T",
      multiple: "2.5",
      min: 100,
      max: 10000,
    },
    {
      label: `180${t("contract.day")}`,
      value: 180,
      coreCount: "8.0",
      multiple: "3.0",
      min: 100,
      max: 20000,
    },
    {
      label: `360${t("contract.day")}`,
      value: 360,
      coreCount: "10.0T",
      multiple: "3.0",
      min: 100,
      max: 30000,
    },
  ];
  /**select选项配置 */
  const [selectActiveOption, setSelectActiveOption] = useState(selectList[0]);

  /**质押算力合约hook */
  const { stakeTrigger, stakeLoading } = useStake({
    successFn: () => {
      getCurDayStakeLimit();
      setAmount("");
      getStakeByLimitFetcher(address, "1000000000000000000", 10);
    },
    errorFn: async () => {
      await getCurDayStakeLimit()
      setTimeout(() => {
        form.validateFields()
      })
    },
  });

  useEffect(() => {
    const option = selectList.find(
      (item) => item.value === selectActive && item
    );
    option && setSelectActiveOption(option);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectActive]);

  // TODO 项目上线前打开校验
  /**
   * @description: 大于等于min小于等于max且是100的倍数
   * @return {Promise<void>}
   */
  const mathematicalCheck = () => {
    return {
      validator(_: any, value: number) {
        const isOver = Number(formatEther(userInfo.poolBalance)) + Number(selectActiveOption.multiple) * Number(value) <= 90000
        const isMin = Number(formatEther(stakeDate.pledgedDay)) + Number(value) <= Number(formatEther(stakeDate.stakeLimit))
        console.log(stakeDate);

        if (!value) {
          return Promise.resolve();
        } else if (balanceLod) {
          return Promise.reject(<div className=" pt-2.5 font-normal text-xs">{t('contract.fromText1')}!</div>);
        } else if (
          value < selectActiveOption.min ||
          value > selectActiveOption.max
        ) {
          return Promise.reject(
            <div className=" pt-2.5 font-normal text-xs">{`${t('contract.fromText2')}${selectActiveOption.min}${t('contract.fromText3')}${selectActiveOption.max}`}{t('contract.fromTextNull')}!</div>
          );
        } else if (value % 100 !== 0) {
          return Promise.reject(<div className=" pt-2.5 font-normal text-xs">{t('contract.fromText4')}</div>);
        } else if (BigInt(value) > balance) {
          return Promise.reject(<div className=" pt-2.5 font-normal text-xs">{t('contract.fromText5')}</div>);
        } else if (!isOver || userInfoLod) {
          if (userInfoLod) {
            return Promise.reject(<div className=" pt-2.5 font-normal text-xs">{t('contract.fromText6')}!</div>);
          }
          return Promise.reject(<div className=" pt-2.5 font-normal text-xs">{t('contract.fromText7')}</div>);
        } else if (!isMin || curDayStakeLimitLod) {
          if (curDayStakeLimitLod) {
            return Promise.reject(<div className=" pt-2.5 font-normal text-xs">{t('contract.fromText8')}!</div>);
          }
          return Promise.reject(
            <div className=" pt-2.5 font-normal text-xs">
              {t('contract.fromText9')}
              {~~(formatEther(stakeDate.stakeLimit))}
              {t('contract.fromText10')}
              {~~formatEther(stakeDate.pledgedDay)}
            </div>);
        } else {
          return Promise.resolve();
        }
      },
    };
  };

  /**
   * @description: select窗获取焦点
   * @return {*}
   */
  const selectOnFocus = () => {
    setSelectDrawerOpen(true);
  };

  /**
   * @description: 提交表单
   * @return {*}
   */
  const onFinish = () => {
    const newAmount = ethers.parseEther(amount);
    const newCycle = selectActive * 24 * 60 * 60;
    stakeTrigger(newAmount, newCycle);
  };
  // 监听翻译和选择发生变化后进行校验，初次不校验
  useNotFirstRender(() => {
    form.validateFields()
  }, selectActiveOption, t)
  return (
    <>
      <div className="box-border mx-2.5  py-9 bg-green27  rounded-lg px-3.5  md:w-125 md:shadow-md md:mx-auto md:mt-6 md:px-8 md:pt-8 md:pb-6 ">
        <Form className="flex flex-col" form={form}
          onFinish={onFinish}>
          <div></div>
          {/* 质押数量 */}
          <Form.Item
            className="mb-9"
            name="amount"
            rules={[
              { required: true, message: <div className=" pt-2.5 font-normal text-xs">{t('contract.fromText')}</div> },
              mathematicalCheck,
            ]}
          >
            <div>
              <div className=" flex text-sm justify-between text-center text-white mb-3.75">
                <div className="w-[36%]">{t('contract.stakingAmount')}</div>
                <div className="w-[23.26%]">{t('contract.periodOfPledge')}</div>
                <div className="w-[16%]">{t('contract.contractComputingPower')}</div>
                <div className="w-[16%]">{t('contract.computingPowerMiningPoolMultiple')}</div>
              </div>
              {/* input */}
              <div className="flex justify-between">
                <Input
                  className="w-[36%]  "
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  placeholder={t('contract.startWith100')}
                />
                <Select
                  value={`${selectActive}${t('contract.day')}`}
                  suffixIcon={
                    <img className=" w-4 h-4" src={SelectSuffixIcon} alt="" />
                  }
                  className="w-[23.26%]"
                  placeholder={t('contract.pleaseChoose')}
                  open={false}
                  onClick={selectOnFocus}
                />
                <div className="w-[16%] h-12 bg-green0B  rounded flex items-center justify-center text-green5B">{selectActiveOption.coreCount}</div>
                <div className="w-[16%] h-12 bg-green0B rounded flex items-center justify-center text-green5B">{selectActiveOption.multiple}</div>
              </div>
            </div>
          </Form.Item>
          {/* submit */}
          <Button className="w-full mt-1" htmlType="submit" loading={stakeLoading}>
            {t('contract.confirm')}
          </Button>

          <div className=" font-medium text-sm hidden items-center justify-end text-greenC2 mt-4 md:flex">
            <img className="h-6 mr-1.5" src={blanceImg} alt="" /> {t('flow.balance')}：{balanceLod ? <LoadingOutlined /> : formatNumber(formatUnits(balance), 4)}
          </div>
        </Form>

        <SelectDrawer
          open={selectDrawerOpen}
          close={() => setSelectDrawerOpen(false)}
          active={selectActive}
          setActive={setSelectActive}
          list={selectList}
        />
      </div>
      <div className=" mx-4 font-medium text-sm flex items-center justify-end text-greenC2 mt-3 md:hidden">
        <img className="h-6 mr-1.5" src={blanceImg} alt="" /> {t('flow.balance')}：{balanceLod ? <LoadingOutlined /> : formatNumber(formatUnits(balance), 4)}
      </div>
    </>
  );
}

/**
 * @description: select框抽屉组件
 * @return {*}
 */
interface SelectDrawerProps {
  open: boolean;
  close: () => void;
  active: number;
  setActive: (active: number) => void;
  list: SelectList[];
}
const SelectDrawer = (props: SelectDrawerProps) => {
  const { open, close, active, setActive, list } = props;
  const { t } = useTranslation();

  /**组件内临时激活选项 防止用户未点击确定按钮更改外部选项 */
  const [tempActive, setTempActive] = useState(active);

  // 监听open被调用时 重置tempActive选项
  useEffect(() => {
    if (open) {
      setTempActive(active);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  /**
   * @description: 确认按钮回调
   * @return {*}
   */
  const confirm = () => {
    setActive(tempActive);
    close();
  };

  return (
    <Drawer
      className="rounded-t-2xl bg-green27 md:max-w-300 md:mx-auto"
      placement="bottom"
      open={open}
      onClose={() => close()}
      closeIcon={false}
      height={500}
    >
      <div className=" w-full">
        <div className="flex justify-between items-center text-white">
          <span className=" text-base">{t('contract.periodOfPledge')}</span>
          <img className=" w-4 h-4" src={CloseIcon} alt="" onClick={close} />
        </div>

        <div className="mt-5 flex flex-col ">
          {list.map((item, index) => (
            <div
              className={`mb-3 border w-full h-14 flex items-center rounded-md  ${tempActive === item.value ? "border-green74 text-white" : "border-grayAD text-grayAD"
                }`}
              key={index}
              onClick={() => setTempActive(item.value)}
            >
              <span className=" text-sm ml-3">{item.label}</span>
            </div>
          ))}
        </div>

        <Button
          className="w-full h-11.5 text-[14px] font-medium"
          onClick={confirm}
        >
          {t('contract.confirm')}
        </Button>
      </div>
    </Drawer>
  );
};
