import useWallet from "@/store/useWallet";
import { useCallback, useEffect, useState } from "react";
// 获取主网币余额
// begin 是否在进入页面就调用
const useMainNetworkCoin = (begin: boolean = true) => {
    // balance是余额，usedBalance是loading
    const [balance, setBalance] = useState<bigint>(BigInt(0))
    // loading
    const [balanceLod, setLoading] = useState(false)
    // 获取地址
    const { wallet: { address, provider } } = useWallet()    // 获取主网币余额
    const getMainNetworkCoin = useCallback(async () => {
        if (provider) {
            setLoading(true)
            try {
                // 获取余额方法
                const res = await provider?.getBalance(address)
                setBalance(res)
            } catch (e) {
                console.log('useMainNetworkCoin', e);
                setBalance(BigInt(0))
            }
            setLoading(false)
        }
    }, [address, provider])
    // 监听函数
    useEffect(() => {
        begin && getMainNetworkCoin()
    }, [begin, getMainNetworkCoin])
    return { balance, balanceLod, getMainNetworkCoin }
}

export default useMainNetworkCoin