import { ProfitsByLimitType } from "@/interface"
import useContract from "@/store/useContract"
import useWallet from "@/store/useWallet"
import { useCallback, useEffect, useState } from "react"
const useGetUpgradeProfitsByLimit = () => {
    // 存放数据
    const [dataUpgradeProfits, setDataUpgradeProfits] = useState<ProfitsByLimitType[]>([])
    // loading
    const [linkUpgradeProfitsLod, setLoading] = useState(true)
    // 获取合约
    const { contract: { HistoricalOrders } } = useContract()
    // 页码 最开始是1000000000000000000，之后获取到最新的，需要进行更新
    const [pagesUpgradeProfits, setPages] = useState('1000000000000000000')
    // 获取
    const { wallet: { address } } = useWallet()
    // 清空所有数据，然后进行加载首页
    const first = () => {
        setPages('1000000000000000000')
        setDataUpgradeProfits([])
        setLoading(true)
    }
    // 获取
    const setUpgradeProfits = () => {
        if (Number(pagesUpgradeProfits)) setLoading(true)
    }
    // 获取数据的方法
    const getUpgradeProfits = useCallback(async () => {
        if (HistoricalOrders && Number(pagesUpgradeProfits) && linkUpgradeProfitsLod) {
            try {
                // 获取到数据
                const list = await HistoricalOrders.getUpgradeProfitsByLimit(address, pagesUpgradeProfits, 1000)
                setDataUpgradeProfits(list)
                setPages("0");
                // 修改页码，获取到得到数据的最后一条，为下一次开始的页码
                // if (list.length) {
                //     const id = Number(formatUnits(list[list.length - 1].id, 0)) - 1 || 0
                //     if (id <= 0) {
                //         setPages('0')
                //     } else {
                //         setPages(id.toString())
                //     }
                //     // 放入data
                //     setDataUpgradeProfits([...dataUpgradeProfits, ...list])
                // } else {
                //     setPages('0')
                // }
            } catch (e) {
                console.log('useGetLinkProfitsByLimit', e);
            }
            setLoading(false)
        }
    }, [HistoricalOrders, address, linkUpgradeProfitsLod, pagesUpgradeProfits])
    useEffect(() => {
        getUpgradeProfits()
    }, [getUpgradeProfits])
    return { dataUpgradeProfits, pagesUpgradeProfits, first, linkUpgradeProfitsLod, setUpgradeProfits }
}

export default useGetUpgradeProfitsByLimit