import useAddressConvert from "@/hooks/useAddressConvert";
import useHasReferrer from "@/web3Hooks/useHasReferrer";
import useRegister from "@/web3Hooks/useRegister";
import { App, Button, Input } from "antd"
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const Invitation = () => {
  const { t } = useTranslation()
  // 获取传来的code
  const { search } = useLocation()
  const { getHasReferrer } = useHasReferrer()
  // 注册
  const { register, registerLod } = useRegister(() => { getHasReferrer() })
  // msg
  const { message } = App.useApp();
  // 检测是不是fb 0x地址的函数
  const { validateAddress, transition0x, validateAddress0x } = useAddressConvert()
  // 邀请者地址
  const [inviteAddress, setInviteAddress] = useState("")
  // 点击确定上链
  const confirm = () => {
    // 检查地址格式正确吗
    if (!inviteAddress) {
      message.warning(t('invitation.pleaseEnterTheAddress'))
    } else if (!validateAddress(inviteAddress)) {
      message.warning(t('invitation.theAddressFormatIsIncorrect'))
    } else {
      const address0x = validateAddress0x(inviteAddress) ? inviteAddress : transition0x(inviteAddress)
      // 这一步代表着地址正确
      register(address0x)
    }
  }
  useEffect(() => {
    // 页面渲染完成解析code
    const data = new URLSearchParams(search)
    const inviteAddress = data.get('inviteAddress') || ''
    setInviteAddress(inviteAddress)
  }, [search])
  return (
    <div className="box-border bg-green26 bg-opacity-85 w-[87.2%]  px-4.5 pt-6 pb-10 shadow-2xl  rounded-lg font-medium absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 md:w-96  ">
      <p className=" text-white text-base mb-4 md:mb-8">{t('invitation.invitationAddress')}</p>
      <Input value={inviteAddress} onChange={(e) => {
        setInviteAddress(e.target.value);
      }} className=" bg-green38 mb-5 border-transparent placeholder-grayAF text-white md:mb-8" placeholder="请输入邀请者地址" />
      <Button loading={registerLod} onClick={confirm} block>{t('invitation.confirm')}</Button>
    </div>
  )
}

export default Invitation