import { Outlet } from "react-router";
import Header from "./component/Header";
import Foot from "./component/Foot";
import { useLocation } from "react-router-dom";
const data = ['/', '', '/invitation', '/pageBg']
const classBg = 'mb-24  box-border bg-bg  bg-no-repeat bg-[length:100%_242px] min-h-[242px] md:min-h-mainH md:bg-mainHBg  md:mb-0'
const Layout = () => {
  const { pathname } = useLocation()
  return (
    <>
      <Header />
      <main
        className={data.findIndex((item) => item === pathname) === -1
          ? 'mb-24 md:mb-0'
          : classBg}>
        <div className="md:backdrop-blur min-h-[242px]  md:min-h-mainH	">
          <Outlet />
        </div>
      </main >
      <Foot />
    </>
  );
};

export default Layout;
