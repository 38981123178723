/* eslint-disable react-hooks/exhaustive-deps */
import InfiniteScroll from "react-infinite-scroll-component";
import { useEffect, useState } from "react";
import useGetUserInfo from "@/web3Hooks/useGetUserInfo";
import BillCard from "./BillCard";
import { formatEther } from "ethers";
import { StakeBill } from "@/web3Hooks/useGetStakeByLimit";
import { LoadingOutlined } from "@ant-design/icons";
import NoDataText from "@/components/NoDataText";
import { useTranslation } from "react-i18next";
import { formatNumber } from "@/utils";

interface BillsProps {
  stakeBills: StakeBill[];
  address: string;
  getStakeByLimitFetcher: (
    userAddress: string,
    startId: string | number,
    limit: number
  ) => Promise<void>;
  getStakeByLimitLoading: boolean;
}

export default function Bills({
  stakeBills,
  address,
  getStakeByLimitFetcher,
}: BillsProps) {
  const { t } = useTranslation();
  const { userInfo, userInfoLod } = useGetUserInfo();

  const [page, setPage] = useState<string>("1000000000000000000");
  const [hasMore, setHasMore] = useState(true)

  useEffect(() => {
    if (parseInt(page) < 0) {
      return setHasMore(false)
    }
    getStakeByLimitFetcher(address, page, 20).then((res) => (!((res as any) || []).length && setHasMore(false)))
  }, [page]);

  //加载更多
  const loadMore = () => {
    const nextId = (
      stakeBills[stakeBills.length - 1].id - BigInt(1)
    ).toString();

    setPage(nextId);
  };

  return (
    <div className="box-border mt-2.5 mx-2.5 bg-green27 bg-opacity-95 rounded-lg md:max-w-300 md:mx-auto">
      <div className="w-full p-3.5">
        <div className="w-full bg-green1A  text-center rounded-md px-4.5 py-3.5 flex flex-col justify-between">
          <span className="text-[12px] text-grayA8 pb-3">
            {t('contract.currentTotalComputingPower')}
          </span>
          <span className="text-[18px] font-bold text-white">
            {userInfoLod ? (
              <LoadingOutlined />
            ) : (
              `${formatNumber(formatEther(userInfo.poolBalance))}`
            )}
            FIBO
          </span>
        </div>
      </div>

      <InfiniteScroll
        dataLength={stakeBills.length}
        next={loadMore}
        hasMore={hasMore}
        loader={
          <div className="flex justify-center my-6 text-white">
            {t('loading')}
            <LoadingOutlined className=" ml-3" />
          </div>
        }
        endMessage={<NoDataText />}
      >
        <div className=" flex flex-wrap justify-center">
          {stakeBills.map((item) => {
            return (
              <BillCard
                key={item.id.toString()}
                address={address}
                id={item.id}
                totalReward={item.totalReward}
                initialAmount={item.initialAmount}
                amount={item.amount}
                startTime={item.startTime}
                endTime={item.endTime}
                lastTime={item.lastTime}
                monthlyRate={item.monthlyRate}
                unlockCycle={item.unlockCycle}
                multiple={item.multiple}
                pool={item.pool}
                isPrivate={item.isPrivate}
              />
            );
          })}
        </div>
      </InfiniteScroll>
    </div>
  );
}
