import { GetDemandsType } from "@/interface"
import useContract from "@/store/useContract"
import useWallet from "@/store/useWallet"
import { useCallback, useState } from "react"

const useGetDemands = () => {
    // 存放数据
    const [demandsData, setData] = useState<GetDemandsType>({
        id: BigInt(0), // 订单ID
        amount: BigInt(0), // 金额
        totalReward: BigInt(0), // 累计收益
        startTime: BigInt(0), // 订单开始时间
        endTime: BigInt(0), // 订单结束时间（指满一年后，订单没有收益）
        lastTime: BigInt(0), // 最近一次提取时间
        monthlyRate: BigInt(0), // 月利率
        isWithdrawn: false // 是否已经提取本金
    })
    // loading
    const [demandsLod, setLoading] = useState(false)
    // 获取合约
    const { contract: { FlowDeposit } } = useContract()
    // 获取
    const { wallet: { address } } = useWallet()
    // 获取数据的方法
    const getDemands = useCallback(async (id: bigint) => {
        setLoading(true)
        if (FlowDeposit) {
            try {
                // 获取到数据
                const data = await FlowDeposit.demands(address, id)
                // 修改页码，获取到得到数据的最后一条，为下一次开始的页码
                setData(data)
                setLoading(false)
                return data
            } catch (e) {
                console.log('useGetDemands', e);
            }
        }
        setLoading(false)
    }, [FlowDeposit, address,])
    return { demandsData, demandsLod, getDemands }
}

export default useGetDemands