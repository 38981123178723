import useContract from "@/store/useContract"
import useIsRegister from "@/store/useIsRegister"
import useWallet from "@/store/useWallet"
import { useCallback, useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"

const useHasReferrer = () => {
    const navigate = useNavigate()
    const { setIsRegister, isRegister } = useIsRegister()
    const { pathname } = useLocation()
    // 获取地址
    const { wallet: { address } } = useWallet()
    // 获取合约
    const { contract: { FFR } } = useContract()
    const getHasReferrer = useCallback(async () => {
        try {
            if (FFR) {
                const is = await FFR.hasReferrer(address)
                // 判断是否注册 true 是 false 否
                // 如果跟缓存一样就不调用修改缓存
                if (isRegister !== is) {
                    setIsRegister(is)
                }
                // 判断有没有注册 和当前这的页面是不是注册页面 注册了切当前页面不是注册页面则跳转到首页，如果没有注册路由守卫会解决的
                if (is && pathname === '/invitation') {
                    navigate('/')
                }
            }
        } catch (e) {
            console.log('useHasReferrer', e);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [FFR, address])
    useEffect(() => {
        getHasReferrer()
    }, [getHasReferrer])
    return { getHasReferrer }
}

export default useHasReferrer