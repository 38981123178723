import { useState } from 'react'
import useListenerTransfer from './useListenerTransfer';
import useContract from '@/store/useContract';
import useNotification from '@/hooks/useNotification';
import { useTranslation } from 'react-i18next';

const useFlowWithdraw = (successFn?: Function, errorFn?: Function) => {
    const { t } = useTranslation()
    // loading
    const [flowWithdrawLod, setLoading] = useState(false)
    // 提示
    const { showNotification } = useNotification();
    // 获取监听事件成功的方法
    const listenerTransferF = useListenerTransfer();
    // 合约
    const { contract: { FlowDeposit } } = useContract()
    // 提去收益函数
    const getFlowWithdraw = async (id: bigint) => {
        if (FlowDeposit) {

            setLoading(true)
            try {
                const { hash } = await FlowDeposit.withdraw(id)
                // 查询交易
                const result = await listenerTransferF(hash);
                if (result) {
                    // 当交易成功时
                    // 提示
                    showNotification(
                        "success",
                        {
                            message: t('hooks.successfulRedemption'),
                            description: t('hooks.goToTheBlockBrowser'),
                        },
                        hash
                    );
                    // 成功后调用成功函数
                    successFn && successFn();
                } else {
                    showNotification(
                        "error",
                        {
                            message: t('hooks.redemptionFailure'),
                            description: t('hooks.goToTheBlockBrowser'),
                        },
                        hash
                    );
                    // 失败后调用失败函数
                    errorFn && errorFn();
                }

            } catch (e) {
                console.log('useFlowWithdraw', e);
            }
            setLoading(false)
        }
    }
    return { getFlowWithdraw, flowWithdrawLod }
}

export default useFlowWithdraw