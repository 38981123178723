import { StakeBill } from "@/web3Hooks/useGetStakeByLimit";
import useContract from "@/store/useContract";
import { Button, Tooltip } from "antd";
import dayjs from "dayjs";
import { formatEther } from "ethers";
import { useEffect, useState } from "react";
import { cheengeNumber } from "@/utils";
import useNotification from "@/hooks/useNotification";
import useListenerTransfer from "@/web3Hooks/useListenerTransfer";
import { useTranslation } from "react-i18next";

interface BillCardProps extends StakeBill {
  address: string; // 用户地址
}

/**
 * @description: 账单卡片组件
 * @return {*}
 */
export default function BillCard(props: BillCardProps) {
  const { t } = useTranslation();
  /**订单信息 */
  const [billInfo, setBillInfo] = useState(props);
  /**当前订单待提取收益 */
  const [pendingStake, setPendingStake] = useState("0");
  /**提取收益loading */
  const [claimLoading, setClaimLoading] = useState(false);
  /**赎回loading */
  const [withdrawLoading, setWithdrawLoading] = useState(false);
  // 获取监听事件成功的方法
  const listenerTransferF = useListenerTransfer();

  /**提示 */
  const { showNotification } = useNotification();

  /**合约 */
  const {
    contract: { FFR },
  } = useContract();

  useEffect(() => {
    getPendingStake();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [FFR, props.address, props.id]);

  /**
   * @description: 获取当前订单代提取收益
   * @return {*}
   */
  const getPendingStake = async () => {
    if (!FFR) return;
    const res = await FFR.pendingStake(
      billInfo.address,
      billInfo.id.toString()
    );

    setPendingStake(formatEther(res));
  };

  /**
   * @description: 更新当前订单信息
   * @return {*}
   */
  const updateStakes = async () => {
    if (!FFR) return;
    const res = await FFR.stakes(billInfo.address, billInfo.id.toString());

    const newObject: BillCardProps = {
      ...billInfo,
      id: res.id,
      initialAmount: res.initialAmount,
      amount: res.amount,
      startTime: res.startTime,
      endTime: res.endTime,
      lastTime: res.lastTime,
      monthlyRate: res.monthlyRate,
      unlockCycle: res.unlockCycle,
      multiple: res.multiple,
      pool: res.pool,
      isPrivate: res.isPrivate,
    };

    setBillInfo(newObject);
  };

  /**
   * @description: 提取收益
   * @return {*}
   */
  const claim = async () => {
    if (!FFR) return;
    try {
      setClaimLoading(true);
      const { hash } = await FFR.claim(billInfo.id.toString());

      const result = await listenerTransferF(hash);

      if (result) {
        await Promise.all([getPendingStake(), updateStakes()]);
        showNotification(
          "success",
          {
            message: t('hooks.successfulExtraction'),
            description: t('hooks.goToTheBlockBrowser'),
          },
          hash
        );
      } else {
        showNotification(
          "error",
          {
            message: t('hooks.retrievalFailure'),
            description: t('hooks.goToTheBlockBrowser'),
          },
          hash
        );
      }
    } catch (error) {
      showNotification("error", {
        message: t('hooks.retrievalFailure'),
      });
      console.error("提取失败", error);
    } finally {
      setClaimLoading(false);
    }
  };

  /**
   * @description: 赎回本金+收益
   * @return {*}
   */
  const withdraw = async () => {
    if (!FFR) return;
    try {
      setWithdrawLoading(true);
      const { hash } = await FFR.withdraw(billInfo.id.toString());

      const result = await listenerTransferF(hash);

      if (result) {
        await Promise.all([getPendingStake(), updateStakes()]);

        showNotification(
          "success",
          {
            message: t('hooks.successfulRedemption'),
            description: t('hooks.goToTheBlockBrowser'),
          },
          hash
        );
      } else {
        showNotification(
          "error",
          {
            message: t('hooks.redemptionFailure'),
            description: t('hooks.goToTheBlockBrowser'),
          },
          hash
        );
      }
    } catch (error) {
      showNotification("error", {
        message: t('hooks.redemptionFailure'),
      });

      console.error("赎回失败", error);
    } finally {
      setWithdrawLoading(false);
    }
  };

  return (
    <div className="w-full mt-3 pt-3 flex flex-col md:w-96 md:mx-1  md:my-4 md:p-4  md:shadow-md md:rounded-xl md: md:border-green-500  md:border-1">
      <div className=" w-16.75 h-6 rounded-e-full bg-green37 text-grayAD flex items-center justify-center">
        <span className=" text-[12px]">
          ID: {(billInfo.id + BigInt(1)).toString()}
        </span>
      </div>

      <div className=" px-5 mt-7">
        {/* 质押 金额&周期 */}
        <div className="w-full">
          <div className="w-full flex items-center justify-between">
            <label className=" text-[13px]  text-grayA8">
              {t('contract.stakingAmount')}
            </label>
            <span className=" text-[13px] text-white font-medium">
              {formatEther(billInfo.initialAmount)} FIBO
            </span>
          </div>

          <div className="w-full flex items-center justify-between mt-6">
            <label className="text-[13px]  text-grayA8">
              {t('contract.periodOfPledge')}

            </label>
            <span className=" text-[13px] text-white font-medium">
              {(billInfo.unlockCycle / BigInt(86400)).toString()}{t('contract.day')}

            </span>
          </div>
        </div>

        {/* 分割线 */}
        <div className="w-full h-[1px] bg-gray38 my-5"></div>

        {/* 算力&余额 */}
        <div className="w-full">
          <div className="w-full flex items-center justify-between">
            <label className=" text-[13px]  text-grayA8">
              {t('contract.currentComputingPower')}
            </label>
            <Tooltip title={formatEther(billInfo.pool)}>
              <span className=" text-[13px] text-white font-medium">
                {cheengeNumber(formatEther(billInfo.pool), 4, '...')} FIBO
              </span>
            </Tooltip>

          </div>
          <div className="w-full flex items-center justify-between mt-6">
            <label className=" text-[13px]  text-grayA8">
              {t('contract.availableBalance')}
            </label>
            <Tooltip title={formatEther(billInfo.amount)}>
              <span className=" text-[13px] text-white font-medium">
                {cheengeNumber(formatEther(billInfo.amount), 4, '...')} FIBO
              </span>
            </Tooltip>

          </div>
        </div>

        {/* 收益 */}
        <div className="w-full">
          <div className="w-full flex items-center justify-between mt-6">
            <label className=" text-[13px]  text-grayA8">
              {t('contract.cumulativeWithdrawalIncome')}
            </label>
            <Tooltip
              title={formatEther(billInfo.totalReward)}
            >
              <span className=" text-[13px] text-white font-medium">
                +
                {cheengeNumber(
                  formatEther(billInfo.totalReward),
                  2,
                  "..."
                )}{" "}
                FIBO
              </span>
            </Tooltip>
          </div>
          <div className="w-full flex items-center justify-between mt-6">
            <label className=" text-[13px]  text-green-600">
              {t('contract.unattractedEarnings')}
            </label>
            <Tooltip title={pendingStake}>
              <span className=" text-[13px] text-white font-medium">
                {cheengeNumber(pendingStake, 4, "...")}FIBO
              </span>
            </Tooltip>
          </div>
        </div>

        {/* 时间 */}
        <div className="w-full flex  justify-between mt-6">
          <label className=" text-[13px]  text-grayA8">
            {t('contract.timeOfPledge')}
          </label>
          <div className="flex flex-col">
            <span className=" text-[13px] text-white font-medium">
              {" "}
              {dayjs
                .unix(Number(billInfo.startTime.toString()))
                .format("YYYY.MM.DD HH:mm")}
              -
            </span>
            <span className=" text-[13px] text-white font-medium">
              {dayjs
                .unix(Number(billInfo.endTime.toString()))
                .format("YYYY.MM.DD HH:mm")}
            </span>
          </div>
        </div>

        {/* 操作 */}
        <div className="w-full mt-7 pb-6 border-b border-gray38 md:border-none">
          {billInfo.amount ? (
            <div className="w-full flex">
              <Button
                style={{ background: 'transparent' }}
                className="flex-1 mr-9  text-green74 border border-green74"
                loading={withdrawLoading}
                onClick={withdraw}
              >
                {t('contract.redemption')}
              </Button>
              <Button className="flex-1" loading={claimLoading} onClick={claim}>
                {t('contract.extractingEarnings')}
              </Button>
            </div>
          ) : (
            <div className="w-full">

              <Button style={{ background: 'transparent' }}
                className="  text-green74 border border-green74 w-full" disabled type="primary">
                {t('contract.redeemed')}
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
