import homeBgItemCom from '@/image/homeBgItemCom.png'
import homeBgItemjs from '@/image/homeBgItemjs.png'
import homeBgItemd from '@/image/homeBgItemd.png'
import homeBgItemzb1 from '@/image/homeBgItemzb1.png'
import homeBgItemzb2 from '@/image/homeBgItemzb2.png'
import homeBgItemzb3 from '@/image/homeBgItemzb3.png'
import FLY from '@/image/FLY.png'
import ADFI from '@/image/ADFI.png'
import funDao from '@/image/funDao.png'
import hkba from '@/image/hkba.png'
import mexc from '@/image/mexc.png'
import american from '@/image/american.png'
import { useTranslation } from 'react-i18next'
const logoData = [
    { logo: american, text: 'american software capital' },
    { logo: mexc, text: 'MEXC' },
    { logo: hkba, text: 'HKBA' },
    { logo: funDao, text: 'FUNDAO' },
    { logo: ADFI, text: 'ADFI' },
    { logo: FLY, text: 'Flyswap' }
]
const Home = () => {
    const { t } = useTranslation()
    return (
        <div className='pt-[260px] md:pt-20 md:max-w-300 md:mx-auto'>
            <div className=' mx-auto max-w-125 md:max-w-none md:flex md:flex-wrap md:justify-center '>
                <Item1 t={t} />
                <Item2 t={t} />
                <Item3 t={t} />
                <Item4 t={t} />
            </div>
            <div className='bg-bgXian bg-cover	bg-center text-white flex items-end justify-center w-full bg-no-repeat h-[75px] mt-4 md:h-[105px] md:my-12 md:text-xl'>
                <span className='mb-2'>{t('home.partner')}</span>
            </div>
            <div className='flex items-center flex-wrap  mx-4 justify-between mt-5'>
                {logoData.map((item) => {
                    return <div key={item.text} className=' rounded-md mb-4 flex flex-col items-center justify-center box-border w-[108px] h-[80px] bg-green2432 text-white md:w-[170px] md:h-[110px]'>
                        {item.text === logoData[1].text ? <img src={item.logo} alt="" className="w-[53px] h-[32px] md:w-[65px] md:h-[40px] " /> :
                            <img src={item.logo} alt="" className="w-[42px] h-[42px] md:w-[50px] md:h-[50px] " />}
                        {item.text === logoData[0].text ? <p className='mt-1.5 text-xs text-xs3 md:text-xs'>{item.text}</p>
                            : <p className='mt-1.5 text-xs md:text-base'>{item.text}</p>}
                    </div>
                })}
            </div>
        </div >
    )
}
const Item1 = (props: { t: any }) => {
    const { t } = props
    return (
        <div style={{ backgroundSize: '100% 100%' }} className='box-border   bg-homeBgItem  mx-4 bg-no-repeat px-3 pb-2 pt-2 md:w-125 md:pb-4 md:m-0 md:mr-5 md:mb-5 '>
            <h4 className=' text-center font-bold text-base text-greenE1F md:text-lg'>FIBO {t('home.introduction')}</h4>
            <div className=" pt-4  flex justify-between">
                <div className=' text-xs2   text-white mt-2 mr-12 md:text-sm  md:mt-7'>
                    <span className='font-bold '>Fibonacci</span>{t('home.text')}
                </div>
                <img className=' w-[65px] h-[49px] md:w-[100px] md:h-[80px] ' src={homeBgItemCom} alt="" />
            </div>
            <div className='pt-1 flex items-center justify-between'>
                <div className=' mr-2 '>
                    <h4 className='mb-1  text-greenA7 text-xs md:text-sm'>FIBO {t('home.introduction')}</h4>
                    <div className='bg-gradient-to-r from-green90  to-transparent h-0.25 w-3/4' />
                    <div className=' mt-2 text-xs2 text-grayDF md:text-3'>
                        {t('home.text1')}
                    </div>
                </div>
                <div style={{ backgroundSize: "100% 100%" }} className='bg-bg777 box-border pl-1.5 mt-2 pr-2 bg-no-repeat min-w-[135px]  h-[92px] pt-1.5 md:min-w-[160px] md:w-[160px] md:h-[135px] md:pt-3'>
                    <p className='text-xxs text-greenE1F md:text-xs md:mb-2'>{t('home.totalCirculation')}: 77777777</p>
                    <div className='bg-gradient-to-r from-green90 mr-1.5 to-transparent h-0.25 ' />
                    <p className='text-[6px] text-greenE1F mt-2 text-right mb-1.5 pr-2 md:text-xxs md:mt-3'>{t('home.releaseCycle')} ： 77 years</p>
                    <div className='bg-gradient-to-r from-green90 mr-1.5 to-transparent h-0.25 w-3/4 ' />
                    <p className='text-[6px] text-greenE1F  text-right my-1 pr-2 md:text-xxs md:my-2'>{t('home.circulation')} : 40%（3100）{t('home.w')}</p>
                    <div className='bg-gradient-to-r from-green90 mr-1.5 to-transparent h-0.25  ' />
                    <p className='text-[6px] text-greenE1F text-right mt-1 md:text-xxs'>{t('home.uncirculated')}： 60%</p>
                </div>
            </div>
        </div>
    )
}
const Item2 = (props: { t: any }) => {
    const { t } = props
    return (
        <div style={{ backgroundSize: '100% 100%' }} className='  mt-4.5 box-border  bg-homeBgItem mx-4 bg-no-repeat px-3 pb-1 pt-2 md:pb-4 md:w-125 md:m-0 md:mb-5 '>
            <h4 className=' text-center font-bold text-base text-greenE1F md:text-lg '>FFR {t('home.introduction')}</h4>
            <div className='mt-1 md:mt-8 '>
                <div className=' mr-2'>
                    <h4 className='mb-1  text-greenA7 text-xs md:text-sm md:mb-3'>FFR {t('home.introduction')}</h4>
                    <div className='bg-gradient-to-r from-green90  to-transparent h-0.25 w-2/4' />
                    <div className=' mt-2 text-xs2 text-grayDF md:text-xs md:mt-4'>
                        {t('home.text2')}
                    </div>
                </div>
            </div>
            <div className='mt-1 flex items-center justify-between'>
                <div className=' text-grayDF text-xs2 md:text-xs md:mt-3'>
                    <div className='bg-gradient-to-r from-green90  to-transparent h-0.25 w-[130%] md:mb-5 ' />
                    <p className='mt-2 flex md:mt-2'> <span className='mr-2 mt-1.5 w-0.5 h-0.5 bg-grayDF block rounded-full md:w-1 md:h-1 ' />{t('home.text3')}</p>
                    <p className='mt-0.5 flex items-center md:mt-2'> <span className='mr-2 w-0.5 h-0.5 bg-grayDF block rounded-full md:w-1 md:h-1 ' />{t('home.text4')}</p>
                    <p className='mt-0.5 flex items-center md:mt-2'> <span className='mr-2 w-0.5 h-0.5 bg-grayDF block rounded-full md:w-1 md:h-1 ' />{t('home.text5')}</p>
                    <p className='mt-0.5 flex items-center md:mt-2'> <span className='mr-2 w-0.5 h-0.5 bg-grayDF block rounded-full md:w-1 md:h-1 ' />{t('home.text6')}</p>
                </div>
                <img className=' w-[121px] h-[82px] md:w-[180px] md:h-[120px]' src={homeBgItemjs} alt="" />
            </div>
        </div>
    )
}
const Item3 = (props: { t: any }) => {
    const { t } = props
    return (
        <div style={{ backgroundSize: '100% 100%' }} className=' box-border mt-4.5   bg-homeBgItem    mx-4 bg-no-repeat px-3 pb-0 pt-2 md:w-125 md:m-0 md:mr-5 md:mb-5'>
            <h4 className=' text-center font-bold text-base text-greenE1F md:text-lg'>{t('home.tokenEconomy')}</h4>
            <div className=" pt-4  flex justify-between">
                <div style={{ backgroundSize: "100% 100%" }} className='flex flex-col items-end bg-bg777 box-border  mt-2 pr-5 py-1.5 bg-no-repeat min-w-[127px] w-[127px] h-[89px] pt-1.5 md:min-w-[180px] md:w-[180px] md:h-[120px]'>
                    <p className=' text-[6px] text-greenE1F my-[1.2px] md:text-xxs md:my-[2.5px]'>LP ： 30%</p>
                    <div className='bg-gradient-to-r from-green90  to-transparent h-0.25 w-4/5' />
                    <p className=' text-[6px] text-greenE1F my-[1.2px] md:text-xxs md:my-[2.5px]'>{t('home.ecologicalConstruction')} ： 15%</p>
                    <div className='bg-gradient-to-r from-green90  to-transparent h-0.25 w-5/6' />
                    <p className=' text-[6px] text-greenE1F my-[1.2px] md:text-xxs md:my-[2.5px]'>{t('home.FIBOFoundation')}： 10%</p>
                    <div className='bg-gradient-to-r from-green90  to-transparent h-0.25 w-3/4' />
                    <p className=' text-[6px] text-greenE1F my-[1.2px] md:text-xxs md:my-[2.5px]'>{t('home.technicalDevelopment')}： 5%</p>
                    <div className='bg-gradient-to-r from-green90  to-transparent h-0.25 w-3/4' />
                    <p className=' text-[6px] text-greenE1F mt-[1.5px] md:text-xxs md:mt-[2.5px]  '>{t('home.communityOperation')}： 40%</p>
                </div>
                <div className=' text-xxs  text-white '>
                    <div className='flex flex-col items-end ml-7'>
                        <h4 className='mb-1  text-greenA7 text-xs md:text-sm md:mb-4'>FFR {t('home.token')}</h4>
                        <div className='bg-gradient-to-l from-green90  to-transparent h-0.25 w-3/4' />
                        <div className=' text-right mt-2 text-xs2 mb-3 md:text-xs md:my-4'>
                            <span className='font-bold'>FFR</span> ({t('home.fibonacciIntelligentHashratePool')}) <span className='font-bold'>FIBO <span className=' text-green90'>AI</span></span> Computility Federal Reserve
                        </div>
                        <div className='bg-gradient-to-l from-green90  to-transparent h-0.25 w-3/4' />
                    </div>
                </div>
            </div>
            <div className='pt-1 flex justify-between'>
                <img className=' w-[87px] h-[72px] mt-3 md:w-[135px] md:h-[115px]' src={homeBgItemd} alt="" />
                <div className=' ml-10  text-xs2 text-grayDF md:text-xs'>
                    <p className='mt-2 flex  md:mt-2'> <span className='mr-2  mt-1.5 w-0.5 h-0.5 bg-grayDF block rounded-full md:w-1 md:h-1' />{t('home.text7')}</p>
                    <p className='mt-0.5 flex  md:mt-2'> <span className='mr-2  mt-1.5 w-0.5 h-0.5 bg-grayDF block rounded-full md:w-1 md:h-1' /> {t('home.text8')}</p>
                    <p className='mt-0.5 flex  md:mt-2'> <span className='mr-2  mt-1.5 w-0.5 h-0.5 bg-grayDF block rounded-full md:w-1 md:h-1' /> {t('home.text9')}</p>
                </div>
            </div>
        </div>
    )
}
const Item4 = (props: { t: any }) => {
    const { t } = props
    return (
        <div style={{ backgroundSize: '100% 100%' }} className=' box-border mt-4.5 bg-homeBgItem  mx-4 bg-no-repeat px-3 pb-6 pt-1 md:w-125 md:m-0  md:mb-5' >
            <h4 className=' text-center font-bold text-base text-greenE1F md:text-lg md:pt-1'>{t('home.capitalEntry')}</h4>
            <div className=' flex items-center justify-between mt-4 md:mt-8 '>
                <div className=' text-xxs text-grayDF md:text-sm '>
                    <p className=' mb-5'>{t('home.text10')}</p>
                    <div className=' flex items-center  md:mt-0 md:flex-col md:items-start'>
                        <img className=' w-[116px] h-[53px] mr-3.5 md:w-[150px] md:h-[75px]' src={homeBgItemzb2} alt="" />
                        <img className=' w-[88px] h-[53px] md:w-[120px] md:h-[73px] md:mt-3 ' src={homeBgItemzb1} alt="" />
                    </div>
                </div>
                <img className=' w-[79px] h-[76px] md:w-[180px] md:h-[175px]' src={homeBgItemzb3} alt="" />
            </div>
        </div>
    )
}

export default Home