import tabHome from '@/image/tabHome.png'
import tabFlowHash from '@/image/tabFlowHash.png'
import tabContractHash from '@/image/tabContractHash.png'
import tabLinkHash from '@/image/tabLinkHash.png'
import tabMy from '@/image/tabMy.png'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
/**
 * @description: 底部导航栏
 * @return {*}
 */
const pathDate = ['/', '/flowHash', '/contractHash', '/linkHash', '/my']
const Foot = () => {
  // 翻译
  const { t } = useTranslation()
  // 路由信息
  const { pathname } = useLocation()
  // 初始化路由
  const navigate = useNavigate()
  // tab 数据
  const tabData = [
    { img: tabHome, text: t('foot.homePage'), path: '/' },
    { img: tabFlowHash, text: t('foot.flowHashrate'), path: '/flowHash' },
    { img: tabContractHash, text: t('foot.contractHashrate'), path: '/contractHash' },
    { img: tabLinkHash, text: t('foot.linkHashrate'), path: '/linkHash' },
    { img: tabMy, text: t('foot.my'), path: '/my' }
  ]
  return (
    <>
      {pathDate.indexOf(pathname) > -1 &&
        <div className='fixed bottom-0 left-0 box-border flex justify-between items-center bg-black04   w-full px-6.5 py-3.75 text-gray73 md:hidden'>
          {tabData.map((item) => {
            return <div onClick={() => { navigate(item.path) }} key={item.path} className={` flex items-center flex-col cursor-pointer  text-3 ${pathname === item.path ? 'text-white' : ''}`}>
              <img className='mb-1.5 w-7.5 h-7.5' src={item.img} alt="" />
              <span>{item.text}</span>
            </div>
          })}
        </div>
      }

    </>
  )
}

export default Foot