import useNotification from "@/hooks/useNotification"
import useContract from "@/store/useContract"
import { useCallback, useState } from "react"
import useListenerTransfer from "./useListenerTransfer"
import { useTranslation } from "react-i18next"

const useFlowDeposit = (successFn?: Function, errorFn?: Function) => {
    const { t } = useTranslation()
    // loading
    const [flowDepositLod, setLoading] = useState(false)
    // 获取合约
    const { contract: { FlowDeposit } } = useContract()
    // 提示
    const { showNotification } = useNotification();
    // 获取监听事件成功的方法
    const listenerTransferF = useListenerTransfer();
    // 质押方法
    const flowDeposit = useCallback(async (amount: bigint) => {
        try {
            setLoading(true)
            if (FlowDeposit) {
                const { hash } = await FlowDeposit.deposit(amount, { value: amount })
                // 查询交易
                const result = await listenerTransferF(hash);
                if (result) {
                    // 当交易成功时
                    // 提示
                    showNotification(
                        "success",
                        {
                            message: t('hooks.successfulPledge'),
                            description: t('hooks.goToTheBlockBrowser'),
                        },
                        hash
                    );
                    // 成功后调用成功函数
                    successFn && successFn();
                } else {
                    showNotification(
                        "error",
                        {
                            message: t('hooks.failureOfPledge'),
                            description: t('hooks.goToTheBlockBrowser'),
                        },
                        hash
                    );
                    // 失败后调用失败函数
                    errorFn && errorFn();
                }
            }
        } catch (e) {
            console.log('useFlowDeposit', e);
        }
        setLoading(false)
    }, [FlowDeposit, errorFn, listenerTransferF, showNotification, successFn, t])
    return { flowDepositLod, flowDeposit }
}

export default useFlowDeposit