
import { useNavigate } from "react-router-dom";
import right from "@/image/right.png";
import { useTranslation } from "react-i18next";
import friendImg from "@/image/friendImg.png";
import hashImg from "@/image/hashImg.png";
import gradeImg from "@/image/gradeImg.png";
import myLinkImg from "@/image/myLinkImg.png";
import messageImg from "@/image/messageImg.png";

const My = () => {
  const { t } = useTranslation()
  // 初始化路由
  const navigate = useNavigate();

  // pageDate
  const pageDate = [
    {
      title: t('my.inviteFriends'),
      img: friendImg,
      link: '/pageNoBg?id=0'
    }, {
      title: t('my.miningPool'),
      img: hashImg,
      link: '/pageBg?id=0'
    }, {
      title: t('my.currentLevel'),
      img: gradeImg,
      link: '/pageBg?id=1'
    }, {
      title: t('my.myLinks'),
      img: myLinkImg,
      link: '/myLink'
    }, {
      title: t('my.publishNews'),
      img: messageImg,
      link: '/pageNoBg?id=1'
    },
  ]
  return (

    <div className=" mx-4 mt-5 md:max-w-300 md:mx-auto md:pt-9 md:mt-0">
      {pageDate.map((item, index) => {
        return <div onClick={() => { navigate(item.link) }} key={index} className="cursor-pointer px-4.5 py-5 rounded-md flex justify-between items-center bg-green22 mb-2.5">
          <div className=" flex items-center ">
            <img className=" w-4.5 h-4.5 mr-2" src={item.img} alt="" />
            <span className=" text-sm text-white">{item.title}</span>
          </div>
          <img className=" w-4 h-4" src={right} alt="" />
        </div>
      })}
    </div>
  );
};

export default My;
