import { Tabs } from "antd";
import type { TabsProps } from "antd";
import Pledge from "./components/Pledge";
import Bills from "./components/Bills";
import useWallet from "@/store/useWallet";
import useGetStakeByLimit from "@/web3Hooks/useGetStakeByLimit";
import { useState } from "react";
import { useTranslation } from "react-i18next";

/**
 * @description: 合约算力路由页面
 * @return {*}
 */
export default function ContractHash() {
  const { t } = useTranslation();
  const [keys, setKeys] = useState("1");
  const { wallet } = useWallet();
  const { stakeBills, getStakeByLimitFetcher, getStakeByLimitLoading } =
    useGetStakeByLimit();

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: t('contract.contractHashrate'),
    },
    {
      key: "2",
      label: t('contract.myBill'),
    },
  ];

  return (
    <div className=" w-full">
      <Tabs
        className="box-border pl-5.25 bg-transparent bg-opacity-85"
        defaultActiveKey={keys}
        centered
        items={items}
        onChange={(key: string) => {
          setKeys(key);
        }}
      />

      {keys === "1" && (
        <Pledge
          getStakeByLimitFetcher={getStakeByLimitFetcher}
          address={wallet.address}
        />
      )}

      {keys === "2" && (
        <Bills
          stakeBills={stakeBills}
          address={wallet.address}
          getStakeByLimitFetcher={getStakeByLimitFetcher}
          getStakeByLimitLoading={getStakeByLimitLoading}
        />
      )}
    </div>
  );
}
